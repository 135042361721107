<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :height="options.height"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    content-class="mx-3"
    overlay-opacity="0.4"
  >
    <v-card
      :height="options.height"
      :max-width="options.width"
      class="d-flex flex-column justify-center"
    >
      <v-card-text
        v-show="!!message"
        class="text-center mt-5 app-confirm-dialog-message"
        >{{ message }}</v-card-text
      >
      <div class="d-flex align-center justify-center mt-6">
        <v-btn
          text
          color="#A0A0A0"
          @click.native="cancel"
          width="100px"
          class="mr-3"
          >キャンセル</v-btn
        >
        <v-btn color="primary" width="100px" @click.native="agree" depressed
          >削除</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: "primary",
      width: 460,
      zIndex: 250,
      height: 220
    }
  }),
  methods: {
    open(message, options) {
      this.dialog = true;

      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.app-confirm-dialog-message {
  white-space: pre-wrap;
  color: #6e6b7b;
}
</style>
