var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "14",
      "height": "15",
      "viewBox": "0 0 14 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6.82468 14.9971C5.34793 14.9971 3.87117 15.0022 2.39442 14.9956C0.795987 14.9887 0.00958 14.2 0.00753073 12.6069C0.00420067 10.335 -0.0168044 8.06239 0.0303288 5.79103C0.0380136 5.42242 0.265226 4.94469 0.550075 4.71389C2.41978 3.19871 4.33943 1.74475 6.22809 0.25237C6.66049 -0.0888333 7.00937 -0.0808924 7.43742 0.256981C9.30865 1.73425 11.1891 3.2005 13.0824 4.65036C13.504 4.97338 13.6677 5.33943 13.6631 5.86711C13.6418 8.1395 13.6582 10.4114 13.6523 12.6832C13.6482 14.1772 12.8372 14.9841 11.3239 14.9946C9.82405 15.0051 8.32449 14.9971 6.82468 14.9971ZM9.55943 13.6095C9.76052 13.6213 9.8512 13.6308 9.94162 13.631C10.3963 13.6328 10.8518 13.6385 11.3064 13.6308C12.0606 13.6177 12.2847 13.401 12.2865 12.6551C12.2921 10.5172 12.3001 8.37926 12.2729 6.24111C12.2696 5.99545 12.1138 5.67397 11.9228 5.52027C10.3799 4.28328 8.80633 3.08446 7.25554 1.8572C6.9625 1.62538 6.75475 1.57466 6.43507 1.8298C4.86942 3.07473 3.28021 4.2902 1.71175 5.5318C1.55575 5.65527 1.38823 5.883 1.38643 6.06359C1.36517 8.33802 1.35288 10.6127 1.40308 12.8864C1.40872 13.1359 1.79065 13.5498 2.04348 13.588C2.7013 13.6884 3.38422 13.6218 4.10044 13.6218C4.10044 11.6145 4.09608 9.6849 4.10274 7.755C4.10505 7.04263 4.33021 6.81644 5.03439 6.8108C6.12614 6.80235 7.21763 6.80824 8.30964 6.8085C9.43853 6.80901 9.55867 6.92889 9.55918 8.05368C9.55995 9.89009 9.55943 11.7262 9.55943 13.6095ZM5.50342 8.20636C5.50342 10.0179 5.50342 11.7967 5.50342 13.5872C6.41201 13.5872 7.28193 13.5872 8.14493 13.5872C8.14493 11.7634 8.14493 9.98461 8.14493 8.20636C7.24017 8.20636 6.38588 8.20636 5.50342 8.20636Z",
      "fill": "#625F6E"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }