<template>
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5462 8.07513H13.2314C13.3655 8.44203 13.4387 8.83798 13.4387 9.25066V14.2195C13.4387 14.3916 13.4087 14.5567 13.3542 14.7103H15.5277C16.3397 14.7103 17.0002 14.0498 17.0002 13.2379V10.5291C17.0002 9.17602 15.8993 8.07513 14.5462 8.07513Z"
      fill="currentColor"
    />
    <path
      d="M3.56154 9.2506C3.56154 8.83788 3.63475 8.44197 3.76876 8.07507H2.45405C1.10089 8.07507 0 9.17596 0 10.5291V13.2379C0 14.0498 0.660511 14.7103 1.47243 14.7103H3.64598C3.59149 14.5566 3.56154 14.3915 3.56154 14.2195V9.2506Z"
      fill="currentColor"
    />
    <path
      d="M10.0026 6.79672H6.99702C5.64385 6.79672 4.54297 7.89761 4.54297 9.25077V14.2196C4.54297 14.4907 4.76271 14.7104 5.03378 14.7104H11.9659C12.2369 14.7104 12.4567 14.4907 12.4567 14.2196V9.25077C12.4567 7.89761 11.3558 6.79672 10.0026 6.79672Z"
      fill="currentColor"
    />
    <path
      d="M8.50061 0.289642C6.87326 0.289642 5.54932 1.61359 5.54932 3.24097C5.54932 4.34481 6.15856 5.30887 7.0583 5.81502C7.48506 6.05508 7.97707 6.19227 8.50061 6.19227C9.02416 6.19227 9.51617 6.05508 9.94293 5.81502C10.8427 5.30887 11.4519 4.34478 11.4519 3.24097C11.4519 1.61362 10.128 0.289642 8.50061 0.289642Z"
      fill="currentColor"
    />
    <path
      d="M3.31753 3.04047C2.10047 3.04047 1.11035 4.03059 1.11035 5.24765C1.11035 6.46471 2.10047 7.45483 3.31753 7.45483C3.62626 7.45483 3.92024 7.39088 4.18729 7.27593C4.64901 7.07714 5.02972 6.72526 5.2653 6.28448C5.43065 5.97513 5.52472 5.62221 5.52472 5.24765C5.52472 4.03062 4.5346 3.04047 3.31753 3.04047Z"
      fill="currentColor"
    />
    <path
      d="M13.6818 3.04047C12.4647 3.04047 11.4746 4.03059 11.4746 5.24765C11.4746 5.62225 11.5687 5.97516 11.734 6.28448C11.9696 6.72529 12.3503 7.07718 12.812 7.27593C13.0791 7.39088 13.3731 7.45483 13.6818 7.45483C14.8989 7.45483 15.889 6.46471 15.889 5.24765C15.889 4.03059 14.8989 3.04047 13.6818 3.04047Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "AdminUsersIcon"
};
</script>

<style scoped></style>
