var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-footer', {
    staticClass: "px-0 mt-10",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('div', _vm._l(_vm.links, function (link) {
    return _c('v-btn', {
      key: link.link,
      staticClass: "text-capitalize ml-1 font-weight-regular",
      attrs: {
        "color": "primary",
        "text": "",
        "href": link.link
      }
    }, [_vm._v(" " + _vm._s(link.text) + " ")]);
  }), 1), _c('div', _vm._l(_vm.icons, function (icon) {
    return _c('v-btn', {
      key: icon.icon,
      staticClass: "mr-1",
      attrs: {
        "href": icon.link,
        "color": _vm.config.light.textColor,
        "icon": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "size": "24px"
      }
    }, [_vm._v(_vm._s(icon.icon))])], 1);
  }), 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }