var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    style: {
      zIndex: _vm.options.zIndex
    },
    attrs: {
      "max-width": _vm.options.width,
      "height": _vm.options.height,
      "content-class": "mx-3",
      "overlay-opacity": "0.4"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.cancel($event);
      }
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    staticClass: "d-flex flex-column justify-center",
    attrs: {
      "height": _vm.options.height,
      "max-width": _vm.options.width
    }
  }, [_c('v-card-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.message,
      expression: "!!message"
    }],
    staticClass: "text-center mt-5 app-confirm-dialog-message"
  }, [_vm._v(_vm._s(_vm.message))]), _c('div', {
    staticClass: "d-flex align-center justify-center mt-6"
  }, [_c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "text": "",
      "color": "#A0A0A0",
      "width": "100px"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.cancel($event);
      }
    }
  }, [_vm._v("キャンセル")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "width": "100px",
      "depressed": ""
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.agree($event);
      }
    }
  }, [_vm._v("削除")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }