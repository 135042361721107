var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "width": "15px",
      "height": "3px"
    },
    attrs: {
      "width": "15",
      "height": "3",
      "viewBox": "0 0 15 3",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "1.5",
      "cy": "1.5",
      "r": "1.5",
      "fill": "#565656"
    }
  }), _c('circle', {
    attrs: {
      "cx": "7.5",
      "cy": "1.5",
      "r": "1.5",
      "fill": "#565656"
    }
  }), _c('circle', {
    attrs: {
      "cx": "13.5",
      "cy": "1.5",
      "r": "1.5",
      "fill": "#565656"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }