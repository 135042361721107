import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import vuetify from "@/plugins/vuetify";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "@/plugins/vee-validate";
import "@/filters/index";
import i18n from "@/plugins/i18n";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import frag from "vue-frag";

Vue.directive("frag", frag);

require("@/services/api");
Vue.use(CKEditor);

Vue.config.productionTip = false;

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
