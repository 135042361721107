var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "height": "18px",
      "wisth": "18px"
    },
    attrs: {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M8.9986 4.49219C6.51264 4.49219 4.49023 6.515 4.49023 9.00096C4.49023 11.4869 6.51264 13.5097 8.9986 13.5097C11.4842 13.5097 13.507 11.4873 13.507 9.00096C13.507 6.5146 11.4842 4.49219 8.9986 4.49219Z",
      "fill": "#7367F0"
    }
  }), _c('path', {
    attrs: {
      "d": "M9.00077 3.16634C8.51489 3.16634 8.12109 2.77254 8.12109 2.28707V0.879672C8.12109 0.3938 8.51489 0 9.00077 0C9.48664 0 9.88044 0.3938 9.88044 0.879672V2.28707C9.88044 2.77254 9.48624 3.16634 9.00077 3.16634Z",
      "fill": "#7367F0"
    }
  }), _c('path', {
    attrs: {
      "d": "M9.00077 14.834C8.51489 14.834 8.12109 15.2278 8.12109 15.7137V17.1207C8.12109 17.6069 8.51489 18.0007 9.00077 18.0007C9.48664 18.0007 9.88044 17.6069 9.88044 17.1207V15.7137C9.88044 15.2278 9.48624 14.834 9.00077 14.834Z",
      "fill": "#7367F0"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.1246 4.87553C12.7814 4.53195 12.7814 3.97513 13.1246 3.63155L14.1198 2.63629C14.463 2.29311 15.0202 2.29311 15.3638 2.63629C15.7074 2.97987 15.7074 3.53709 15.3638 3.88027L14.3685 4.87553C14.0254 5.21911 13.4685 5.21911 13.1246 4.87553Z",
      "fill": "#7367F0"
    }
  }), _c('path', {
    attrs: {
      "d": "M4.87568 13.1252C4.5321 12.7812 3.97528 12.7812 3.6317 13.1252L2.63644 14.12C2.29326 14.4632 2.29286 15.0208 2.63644 15.364C2.98002 15.7072 3.53724 15.7072 3.88042 15.364L4.87568 14.3683C5.21926 14.0252 5.21926 13.468 4.87568 13.1252Z",
      "fill": "#7367F0"
    }
  }), _c('path', {
    attrs: {
      "d": "M14.834 8.99881C14.834 8.51294 15.2278 8.11914 15.7137 8.11914H17.1211C17.6069 8.11914 18.0007 8.51294 18.0007 8.99881C18.0007 9.48469 17.6069 9.87809 17.1211 9.87809H15.7137C15.2278 9.87809 14.834 9.48469 14.834 8.99881Z",
      "fill": "#7367F0"
    }
  }), _c('path', {
    attrs: {
      "d": "M3.16634 8.99881C3.16634 8.51294 2.77254 8.11914 2.28667 8.11914H0.879672C0.3938 8.11914 0 8.51294 0 8.99881C0 9.48469 0.3938 9.87809 0.879672 9.87809H2.28707C2.77254 9.87809 3.16634 9.48469 3.16634 8.99881Z",
      "fill": "#7367F0"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.1249 13.1265C13.4684 12.7833 14.0257 12.7833 14.3688 13.1265L15.3641 14.1218C15.7077 14.4646 15.7077 15.0222 15.3641 15.3654C15.0205 15.7085 14.4637 15.7085 14.1201 15.3654L13.1249 14.3701C12.7813 14.0265 12.7813 13.4697 13.1249 13.1265Z",
      "fill": "#7367F0"
    }
  }), _c('path', {
    attrs: {
      "d": "M4.87583 4.87543C5.21941 4.53185 5.21941 3.97503 4.87583 3.63145L3.88057 2.63659C3.53699 2.29301 2.98017 2.29301 2.63659 2.63659C2.29301 2.97977 2.29301 3.53699 2.63659 3.88017L3.63185 4.87543C3.97543 5.21941 4.53225 5.21941 4.87583 4.87543Z",
      "fill": "#7367F0"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }