var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "9",
      "height": "9",
      "viewBox": "0 0 9 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M4.99352 4.56569C4.99352 5.81304 4.98902 7.06052 4.99647 8.30772C4.99872 8.6815 4.92208 9.00424 4.4808 8.99763C4.07383 8.99158 3.99114 8.68741 3.9917 8.33079C3.99691 5.78618 3.99691 3.24157 3.9917 0.696956C3.991 0.339628 4.07552 0.0364407 4.48248 0.0318001C4.92377 0.026597 4.9983 0.350597 4.99633 0.723675C4.98902 2.00435 4.99352 3.28502 4.99352 4.56569Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6.99061 5.96222C6.99061 5.1975 6.99581 4.43278 6.98836 3.66806C6.98484 3.30328 7.09833 3.0209 7.50319 3.02695C7.87599 3.03258 7.99284 3.29808 7.992 3.64429C7.9875 5.22351 7.9875 6.80315 7.99186 8.38265C7.99299 8.72915 7.87486 8.99381 7.50206 8.99859C7.0972 9.00379 6.98484 8.72058 6.9885 8.3565C6.99581 7.55859 6.99061 6.76026 6.99061 5.96222Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1.99726 7.06776C1.99741 7.51635 1.99544 7.96523 1.99811 8.41382C1.99994 8.76707 1.84989 9.00895 1.47934 8.99966C1.13805 8.99081 0.996858 8.75695 0.997561 8.42648C0.999671 7.47923 0.998405 6.53198 0.998405 5.58473C0.998405 5.25257 1.15014 5.02743 1.49158 5.02349C1.83273 5.01942 1.99459 5.23851 1.99642 5.57221C1.99895 6.07073 1.99712 6.56938 1.99726 7.06776Z",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }