import axios from "@/services/api";
import {
  FETCH_MAILING_LIST,
  FETCH_MAILING_LIST_SUCCESS,
  CREATE_MAILING_USER,
  UPDATE_MAILING_USER,
  DELETE_MAILING_USER
} from "@/store/actions/mailing";

function initialState() {
  return {
    mailingList: [],
    mailingListPagination: null
  };
}

const state = initialState();

const actions = {
  [FETCH_MAILING_LIST]: ({ dispatch, commit }, params) => {
    const { shop_id } = params;
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${shop_id}/mailing-lists`, { params })
        .then(response => {
          commit(FETCH_MAILING_LIST_SUCCESS, response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [UPDATE_MAILING_USER]: (_, params) => {
    const { shop_id, id } = params;
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/shops/${shop_id}/mailing-lists/${id}`, params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CREATE_MAILING_USER]: (_, params) => {
    const { shop_id } = params;
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/shops/${shop_id}/mailing-lists`, params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [DELETE_MAILING_USER]: (_, params) => {
    const { shop_id, id } = params;
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/shops/${shop_id}/mailing-lists/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  [FETCH_MAILING_LIST_SUCCESS]: (state, params) => {
    state.mailingList = params.data.mailing_list;
    if (params.paginate !== undefined) {
      state.mailingListPagination = params.paginate;
    }
  }
};

const getters = {
  getMailingList: state => state.mailingList,
  getMailingListPagination: state => state.mailingListPagination
};

export default {
  state,
  getters,
  actions,
  mutations
};
