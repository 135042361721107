import axios from "@/services/api";
import {
  MASTER_GET_ALL,
  MASTER_GET_ALL_SUCCESS,
  PREFECTURES_GET_ALL,
  PREFECTURES_GET_ALL_SUCCESS
} from "@/store/actions/master";

function initialState() {
  return {
    masters: {},
    prefectures: []
  };
}

const state = initialState();

const getters = {
  getMasterData: state => state.masters,
  getPrefectures: state => state.prefectures
};

const actions = {
  [MASTER_GET_ALL]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/admin/master-data")
        .then(response => {
          commit("MASTER_GET_ALL_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [PREFECTURES_GET_ALL]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/admin/prefectures")
        .then(response => {
          commit("PREFECTURES_GET_ALL_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [MASTER_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.masters = params;
  },

  [PREFECTURES_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.prefectures = params.prefectures;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
