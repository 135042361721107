var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M14.3977 3.27193C12.9161 1.33656 10.6632 0.226562 8.21678 0.226562C6.49681 0.226562 4.8658 0.779139 3.49999 1.82463C3.00581 2.20298 2.55874 2.64045 2.16954 3.12331L1.8225 1.15759C1.7845 0.942619 1.57928 0.798849 1.36452 0.837058L0.32656 1.02025C0.223318 1.03848 0.131564 1.09698 0.0714317 1.18283C0.0112992 1.26874 -0.0122057 1.37493 0.00602902 1.47817L0.830599 6.14906C0.864486 6.34079 1.03123 6.47571 1.21938 6.47571C1.2422 6.47571 1.26533 6.47371 1.28858 6.4696L5.95952 5.64503C6.17449 5.60708 6.318 5.40207 6.28005 5.1871L6.09681 4.14914C6.07857 4.0459 6.02013 3.95415 5.93422 3.89402C5.84832 3.83388 5.74213 3.81043 5.63888 3.82861L3.69245 4.17223C3.96624 3.84864 4.27612 3.55346 4.62127 3.28926C5.66276 2.49205 6.90646 2.0707 8.21799 2.0707C10.0846 2.0707 11.8032 2.91724 12.9332 4.3932C13.8958 5.65067 14.3111 7.20779 14.1026 8.77766C13.8941 10.3476 13.0867 11.7423 11.8292 12.7049C10.7847 13.5045 9.54297 13.9271 8.23813 13.9271C7.97483 13.9271 7.70784 13.9094 7.4446 13.8744C5.87472 13.6659 4.47997 12.8586 3.51733 11.601C2.93139 10.8357 2.5376 9.93257 2.3785 8.98937C2.36105 8.88602 2.30324 8.79379 2.21781 8.73302C2.13243 8.67226 2.02629 8.64802 1.92294 8.66536L0.883616 8.84069C0.668383 8.87701 0.523348 9.08096 0.559607 9.2962C0.768463 10.5344 1.28473 11.7192 2.05264 12.7223C3.31474 14.371 5.14343 15.4296 7.2017 15.7029C7.54605 15.7487 7.89551 15.7719 8.24034 15.7719C9.9524 15.7719 11.5811 15.2177 12.9502 14.1696C14.599 12.9075 15.6576 11.0788 15.9309 9.02051C16.2045 6.9622 15.6599 4.92064 14.3977 3.27193Z",
      "fill": "#C4C4C4"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }