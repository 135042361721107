var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6.37106 6.50422C4.60591 6.4837 3.17733 5.03278 3.17773 3.26072C3.17814 1.43645 4.66888 -0.0177224 6.50147 0.0182307C8.26134 0.052762 9.68586 1.52907 9.6625 3.29423C9.63873 5.09879 8.17644 6.52493 6.37106 6.50422ZM8.35864 3.24081C8.34645 2.16993 7.48297 1.31498 6.41595 1.31701C5.34812 1.31925 4.48809 2.17786 4.48119 3.24893C4.47408 4.34581 5.36031 5.22331 6.45638 5.20422C7.52298 5.18573 8.37103 4.31067 8.35864 3.24081Z",
      "fill": "#625F6E"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6.43585 7.80008C7.16934 7.80008 7.90323 7.78688 8.63651 7.80272C10.5414 7.84375 11.8798 9.17585 11.9284 11.0704C11.9389 11.4803 11.9359 11.8904 11.9271 12.3003C11.9184 12.7145 11.6712 12.9623 11.284 12.9633C10.8975 12.9643 10.6472 12.7202 10.6373 12.3048C10.6275 11.9168 10.6405 11.528 10.6312 11.1397C10.6026 9.91258 9.81565 9.11206 8.60157 9.1013C7.15593 9.0885 5.71009 9.08992 4.26445 9.10049C3.00995 9.10983 2.22751 9.90669 2.20618 11.1754C2.20009 11.542 2.20557 11.9087 2.20314 12.2755C2.20049 12.6889 1.96832 12.9513 1.58218 12.9627C1.15989 12.9753 0.905573 12.709 0.910245 12.2652C0.916745 11.6617 0.868808 11.0466 0.968339 10.4574C1.22367 8.94266 2.48528 7.87341 4.0406 7.81125C4.83767 7.77956 5.63696 7.80577 6.43545 7.80577C6.43585 7.80353 6.43585 7.8017 6.43585 7.80008Z",
      "fill": "#625F6E"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }