<template>
  <svg
    width="7"
    height="7"
    viewBox="0 0 7 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L6 6" stroke="currentColor" stroke-linecap="round" />
    <path d="M1 6L6 1" stroke="currentColor" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: "CrossIcon"
};
</script>

<style scoped></style>
