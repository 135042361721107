import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import config from "@/config";
import icons from "@/icons";

Vue.use(Vuetify);
const opts = {
  icons: {
    iconfont: "mdi", // default - only for display purposes
    values: icons
  },
  theme: {
    themes: {
      light: config.light
    }
  }
};

export default new Vuetify(opts);
