export const GET_ALL_LINK_POSTS = "GET_ALL_LINK_POSTS";
export const LINK_POST_GET = "LINK_POST_GET";
export const ADD_LINK_POST = "ADD_LINK_POST";
export const EDIT_LINK_POST = "EDIT_LINK_POST";
export const DELETE_LINK_POST = "DELETE_LINK_POST";
export const LINK_POSTS_REQUEST = "LINK_POSTS_REQUEST";

export const GET_ALL_LINK_POSTS_SUCCESS = "GET_ALL_LINK_POSTS_SUCCESS";
export const GET_ALL_LINK_POSTS_ERROR = "GET_ALL_LINK_POSTS_ERROR";
export const GET_LINK_POST_SUCCESS = "GET_LINK_POST_SUCCESS";
export const GET_LINK_POST_ERROR = "GET_LINK_POST_ERROR";
export const ADD_LINK_POST_SUCCESS = "ADD_LINK_POST_SUCCESS";
export const ADD_LINK_POST_ERROR = "ADD_LINK_POST_ERROR";
export const EDIT_LINK_POST_SUCCESS = "EDIT_LINK_POST_SUCCESS";
export const EDIT_LINK_POST_ERROR = "EDIT_LINK_POST_ERROR";

export const DELETE_LINK_POST_SUCCESS = "DELETE_LINK_POST_SUCCESS";
export const DELETE_LINK_POST_ERROR = "DELETE_LINK_POST_ERROR";

export const GET_ALL_LINK_POST_USER_VIEWS = "GET_ALL_LINK_POST_USER_VIEWS";
export const GET_ALL_LINK_POST_USER_VIEWS_SUCCESS =
  "GET_ALL_LINK_POST_USER_VIEWS_SUCCESS";
export const GET_ALL_LINK_POST_USER_VIEWS_ERROR =
  "GET_ALL_LINK_POST_USER_VIEWS_ERROR";
