<template>
  <svg
    width="6"
    height="14"
    viewBox="0 0 6 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 7L-9.41288e-08 13.0622L4.35844e-07 0.937822L6 7Z"
      fill="#C4C4C4"
    />
  </svg>
</template>

<script>
export default {
  name: "RightArrowIcon"
};
</script>

<style scoped></style>
