import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "@/store/modules/auth";
import linkposts from "@/store/modules/notification/linkposts";
import stats from "@/store/modules/stats";
import users from "@/store/modules/users";
import notifications from "@/store/modules/notification/notifications";
import categories from "@/store/modules/notification/categories";
import tags from "@/store/modules/notification/tags";
import faq from "@/store/modules/faq";
import shop from "@/store/modules/shop";
import master from "@/store/modules/master";
import business from "@/store/modules/business";
import cashbook from "@/store/modules/cashbook";
import accounts from "@/store/modules/accounts";
import goal from "@/store/modules/goal";
import shared_account from "@/store/modules/shared_account";
import supplier from "@/store/modules/supplier";
import calculationTest from "@/store/modules/calculation_test";
import fixedCost from "@/store/modules/fixedcost";
import inventoryManagement from "@/store/modules/inventorymanagement";
import Mailing from "@/store/modules/mailing";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: true,
    apiProcessing: false,
    alert: {
      show: false,
      text: null,
      disabled: false
    },
    showDeleteDialog: false,
    deals: [],
    balances: [],
    dashboard: null,
    modal: {
      loading: false,
      visible: false,
      title: "",
      text: "",
      action: {
        function: null,
        process: {
          color: "",
          text: ""
        },
        cancel: {
          color: "",
          text: ""
        }
      }
    }
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    setApiProcessing(state, payload) {
      state.apiProcessing = payload;
    },
    setAlert(state, payload) {
      state.alert = payload;
    },
    enableAlert(state) {
      state.alert.disabled = false;
    },
    disableAlert(state) {
      state.alert.disabled = true;
    },
    setDeleteSupplierDialog(state, payload) {
      state.showDeleteDialog = payload;
    },
    showModal(state, params) {
      state.modal.visible = true;
      state.modal.text = params.text;
      state.modal.title = params.title;
      state.modal.action.process = params.action.process;
      state.modal.action.cancel = params.action.cancel;
      state.modal.action.function = params.func;
    },
    hideModal(state) {
      state.modal.visible = false;
      state.modal.title = "";
      state.modal.text = "";
      state.modal.action = {
        function: null,
        process: {
          color: "",
          text: ""
        },
        cancel: {
          color: "",
          text: ""
        }
      };
    },
    startModalLoading(state) {
      state.modal.loading = true;
    },
    stopModalLoading(state) {
      state.modal.loading = false;
    }
  },
  actions: {
    TOGGLE_DRAWER({ commit }) {
      commit("toggleDrawer");
    },
    API_PROCESSING({ commit }, payload) {
      commit("setApiProcessing", payload);
    },
    ALERT({ commit }, payload) {
      commit("setAlert", payload);
    },
    // Disable alert temporarily for specific endpoint
    DISABLE_ALERT({ commit }) {
      commit("disableAlert");
    },
    ENABLE_ALERT({ commit }) {
      commit("enableAlert");
    },
    SUPPLIER_DIALOG({ commit }, payload) {
      commit("setDeleteSupplierDialog", payload);
    }
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer;
    },
    getModalVisibility: state => state.modal.visible,
    getModalTitle: state => state.modal.title,
    getModalText: state => state.modal.text,
    getModalAction: state => state.modal.action,
    getModalLoading: state => state.modal.loading
  },
  modules: {
    auth,
    linkposts,
    stats,
    users,
    notifications,
    tags,
    categories,
    faq,
    shop,
    master,
    business,
    cashbook,
    accounts,
    goal,
    shared_account,
    supplier,
    calculationTest,
    fixedCost,
    inventoryManagement,
    Mailing
  },
  plugins: [createPersistedState()]
});
