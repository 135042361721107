import axios from "@/services/api";
import {
  SUPPLIER_REQUEST,
  SUPPLIER_GET_ALL,
  SUPPLIER_GET_ALL_SUCCESS,
  SUPPLIER_REGISTER,
  SUPPLIER_REGISTER_SUCCESS,
  SUPPLIER_UPDATE,
  SUPPLIER_UPDATE_SUCCESS,
  SUPPLIER_DELETE,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_GET,
  SUPPLIER_GET_SUCCESS
  // SUPPLIER_CASH_FLOW_GET_ALL,
  // SUPPLIER_CASH_FLOW_GET_ALL_SUCCESS,
  // SUPPLIER_ACCOUNT_PAYABLE_GET_ALL,
  // SUPPLIER_ACCOUNT_PAYABLE_GET_ALL_SUCCESS
} from "@/store/actions/supplier";

function initialState() {
  return {
    suppliers: [],
    supplier: {},
    supplierPagination: {},
    supplierCategories: []
    // supplier_account_payables: [],
    // supplier_account_pay_pagination: {},
    // supplier_cash_flows: [],
    // supplier_cash_flow_pagination: {}
  };
}

const state = initialState();

const getters = {
  getSuppliers: state => state.suppliers,
  getSupplier: state => state.supplier,
  getSupplierPagination: state => state.supplierPagination,
  getSupplierCategories: state => state.supplierCategories
  // getSupplierAccountPayables: state => state.supplier_account_payables,
  // getSupplierAccountPayPagination: state =>
  //   state.supplier_account_pay_pagination,
  // getSupplierCashFlows: state => state.supplier_cash_flows,
  // getSupplierCashFlowPagination: state => state.supplier_cash_flow_pagination
};

const actions = {
  [SUPPLIER_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SUPPLIER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/partners`, { params })
        .then(response => {
          commit("SUPPLIER_GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SUPPLIER_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SUPPLIER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/partners/${params.id}`)
        .then(response => {
          commit("SUPPLIER_GET_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SUPPLIER_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SUPPLIER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(`/admin/partners/${params.id}`)
        .then(response => {
          commit("SUPPLIER_DELETE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SUPPLIER_REGISTER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SUPPLIER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/admin/partners`, params)
        .then(response => {
          commit("SUPPLIER_REGISTER_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SUPPLIER_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SUPPLIER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/admin/partners/${params.id}`, params)
        .then(response => {
          commit("SUPPLIER_UPDATE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }

  // [SUPPLIER_ACCOUNT_PAYABLE_GET_ALL]: ({ commit, dispatch }, params) => {
  //   return new Promise((resolve, reject) => {
  //     commit("SUPPLIER_REQUEST");
  //     dispatch("API_PROCESSING", true, { root: true });
  //     axios
  //       .get(
  //         `/admin/shops/${params.shop_id}/partner/${params.partner_id}/accounts-payables`,
  //         { params }
  //       )
  //       .then(response => {
  //         commit("SUPPLIER_ACCOUNT_PAYABLE_GET_ALL_SUCCESS", response.data);
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       })
  //       .finally(() => {
  //         dispatch("API_PROCESSING", false, { root: true });
  //       });
  //   });
  // },
  // [SUPPLIER_CASH_FLOW_GET_ALL]: ({ commit, dispatch }, params) => {
  //   return new Promise((resolve, reject) => {
  //     commit("SUPPLIER_REQUEST");
  //     dispatch("API_PROCESSING", true, { root: true });
  //     axios
  //       .get(
  //         `/admin/shops/${params.shop_id}/partner/${params.partner_id}/cash-flows`,
  //         { params }
  //       )
  //       .then(response => {
  //         commit("SUPPLIER_CASH_FLOW_ALL_SUCCESS", response.data);
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       })
  //       .finally(() => {
  //         dispatch("API_PROCESSING", false, { root: true });
  //       });
  //   });
  // }
};

const mutations = {
  [SUPPLIER_REQUEST]: state => {
    state.status = "loading";
  },

  [SUPPLIER_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.suppliers = params.data.partners;
    state.supplierPagination = params.paginate;
  },

  [SUPPLIER_REGISTER_SUCCESS]: state => {
    state.status = "success";
  },

  [SUPPLIER_UPDATE_SUCCESS]: state => {
    state.status = "success";
  },

  [SUPPLIER_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.supplier = params.data.partners;
  },

  [SUPPLIER_DELETE_SUCCESS]: state => {
    state.status = "success";
  }

  // [SUPPLIER_ACCOUNT_PAYABLE_GET_ALL_SUCCESS]: (state, params) => {
  //   state.status = "success";
  //   state.supplier_account_payables = params;
  //   state.supplier_account_pay_pagination = params;
  // },

  // [SUPPLIER_CASH_FLOW_GET_ALL_SUCCESS]: (state, params) => {
  //   state.status = "success";
  //   state.supplier_cash_flows = params;
  //   state.supplier_cash_flow_pagination = params;
  // }
};

export default {
  state,
  getters,
  actions,
  mutations
};
