<template>
  <svg
    style="height:15px; width:15px;"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.93944 1.47539V1.01367C7.93944 0.770771 7.74289 0.574219 7.49999 0.574219C7.25709 0.574219 7.06053 0.770771 7.06053 1.01367V1.47539C3.14601 1.70481 0 4.95272 0 8.92382C0 9.16672 0.196552 9.36327 0.439452 9.36327C0.682352 9.36327 0.878905 9.16672 0.878905 8.92382C0.878905 8.81439 1.34669 8.48437 2.19726 8.48437C3.04784 8.48437 3.54492 8.81439 3.54492 8.92382C3.54492 9.16672 3.74147 9.36327 3.98437 9.36327C4.22727 9.36327 4.42382 9.16672 4.42382 8.92382C4.42382 8.81439 4.8916 8.48437 5.74218 8.48437C6.59275 8.48437 7.06053 8.81439 7.06053 8.92382V13.8164C7.06053 14.7859 7.84888 15.5742 8.81834 15.5742C9.7878 15.5742 10.5762 14.7859 10.5762 13.8164C10.5762 13.5735 10.3796 13.3769 10.1367 13.3769C9.8938 13.3769 9.69725 13.5735 9.69725 13.8164C9.69725 14.3009 9.30285 14.6953 8.81834 14.6953C8.33383 14.6953 7.93944 14.3009 7.93944 13.8164V8.92382C7.93944 8.81439 8.40722 8.48437 9.25779 8.48437C10.0266 8.48437 10.4624 8.74707 10.543 8.87967C10.5428 8.88207 10.5429 8.8845 10.5427 8.8869C10.5238 9.12852 10.7045 9.3401 10.9465 9.3594C10.9581 9.36025 10.9697 9.36069 10.9813 9.36069C10.9852 9.36069 10.9885 9.3587 10.9923 9.35861C11.0005 9.35905 11.0074 9.36327 11.0156 9.36327C11.2585 9.36327 11.4551 9.16672 11.4551 8.92382C11.4551 8.81439 11.9229 8.48437 12.7734 8.48437C13.624 8.48437 14.1211 8.81439 14.1211 8.92382C14.1211 9.16672 14.3176 9.36327 14.5605 9.36327C14.8034 9.36327 15 9.16672 15 8.92382C15 4.95272 11.854 1.70481 7.93944 1.47539Z"
      v-bind="{ fill }"
    />
  </svg>
</template>

<script>
export default {
  name: "RainyWeatherIcon",
  props: {
    fill: {
      type: String,
      required: false,
      default: "#C1C1C1"
    }
  }
};
</script>

<style lang="scss" scoped></style>
