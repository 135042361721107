<template>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.52701 10.036C8.57514 10.036 9.62389 10.0223 10.6723 10.0388C13.2067 10.0785 14.922 11.7885 14.9658 14.3088C14.9745 14.7776 14.9686 15.2467 14.9658 15.7157C14.962 16.2763 14.667 16.641 14.1208 16.6292C13.5754 16.6173 13.3051 16.2373 13.3098 15.6798C13.3148 15.1832 13.3242 14.6857 13.3033 14.1898C13.2423 12.7217 12.2301 11.7135 10.7567 11.7001C8.57701 11.6795 6.3967 11.6779 4.21701 11.7013C2.79826 11.7167 1.78076 12.7317 1.71264 14.147C1.69014 14.6151 1.69389 15.0857 1.70857 15.5542C1.72764 16.1648 1.50545 16.637 0.85451 16.6273C0.258573 16.6182 0.0270103 16.1738 0.0454478 15.5923C0.0616978 15.0963 0.0360728 14.5985 0.0538853 14.1026C0.132948 11.8517 1.79451 10.1476 4.05076 10.0529C5.20732 10.0042 6.36795 10.0445 7.52732 10.0445C7.52701 10.0417 7.52701 10.0385 7.52701 10.036Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.54553 0.089958C9.83116 0.106833 11.6774 1.99621 11.6515 4.29215C11.6258 6.57402 9.72522 8.41246 7.42897 8.37683C5.18272 8.34246 3.35678 6.47371 3.36428 4.21652C3.37209 1.92808 5.25147 0.073083 7.54553 0.089958ZM9.99334 4.21808C9.98522 2.86215 8.86928 1.7509 7.51334 1.7484C6.15678 1.7459 5.03647 2.85215 5.02303 4.20777C5.00928 5.59652 6.15584 6.73808 7.54459 6.71871C8.89866 6.70027 10.0012 5.57402 9.99334 4.21808Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6253 4.19259C16.641 5.98134 15.4556 7.64634 13.8053 8.15509C13.2875 8.31478 12.7916 8.33728 12.5606 7.73384C12.3306 7.13228 12.6903 6.79196 13.2069 6.60134C14.311 6.19384 14.9406 5.39946 14.946 4.24259C14.9516 3.0829 14.3144 2.2954 13.2197 1.87759C12.7153 1.68509 12.3191 1.35634 12.5594 0.751026C12.8122 0.114464 13.3341 0.155714 13.8785 0.346026C15.5041 0.914776 16.61 2.46134 16.6253 4.19259Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9416 14.784C19.9413 15.0599 19.936 15.3359 19.9426 15.6115C19.956 16.1831 19.7419 16.6284 19.1297 16.6406C18.5182 16.6531 18.2804 16.2231 18.2841 15.6456C18.2866 15.204 18.2957 14.7621 18.2804 14.3209C18.2416 13.1699 17.7401 12.3209 16.646 11.8709C16.101 11.6465 15.5857 11.3602 15.8997 10.6571C16.2032 9.97774 16.7713 10.1287 17.3169 10.3434C19.0644 11.0306 19.9413 12.5127 19.9416 14.784Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "UsersIcon"
};
</script>

<style scoped></style>
