var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "18",
      "height": "12",
      "viewBox": "0 0 18 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M15.8927 5.31837C15.942 5.0498 15.9669 4.77587 15.9669 4.5C15.9669 2.01857 13.9484 0 11.4669 0C9.88293 0 8.41469 0.843767 7.60806 2.18457C6.51919 1.83157 5.40199 1.98293 4.48159 2.6172C3.56119 3.25097 3.01186 4.2544 2.96743 5.35157C1.75746 5.92627 0.933594 7.1499 0.933594 8.5C0.933594 8.73437 0.99036 8.9629 1.03379 9.18457L1.03429 9.18507C1.35756 10.8164 2.80139 12 4.46693 12H14.4669C16.3966 12 18.0003 10.4297 18.0003 8.5C18.0003 7.13527 17.1652 5.90673 15.8927 5.31837Z",
      "fill": "#7367F0"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }