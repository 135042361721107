var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "14",
      "height": "18",
      "viewBox": "0 0 14 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M7.9995 9.09136C7.9995 11.5882 7.9905 14.0853 8.00542 16.5819C8.00992 17.3301 7.85651 17.9761 6.97318 17.9629C6.15854 17.9508 5.99302 17.3419 5.99415 16.6281C6.00456 11.5344 6.00456 6.44083 5.99415 1.34721C5.99274 0.631937 6.16192 0.025038 6.97656 0.0157487C7.85988 0.0053335 8.00907 0.653893 8.00513 1.40069C7.9905 3.96425 7.9995 6.52781 7.9995 9.09136Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M11.9949 11.8895C11.9949 10.3587 12.0053 8.82795 11.9904 7.29719C11.9834 6.567 12.2105 6.00176 13.0209 6.01386C13.7672 6.02512 14.0011 6.55658 13.9994 7.24962C13.9904 10.4108 13.9904 13.5728 13.9991 16.7345C14.0014 17.4281 13.7649 17.9579 13.0187 17.9675C12.2083 17.9779 11.9834 17.4109 11.9907 16.6822C12.0053 15.085 11.9949 13.4869 11.9949 11.8895Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M2.00114 14.1C2.00142 14.9979 1.99748 15.8965 2.00283 16.7944C2.00649 17.5015 1.70613 17.9857 0.9644 17.9671C0.281217 17.9494 -0.00140224 17.4813 5.22914e-06 16.8198C0.00422762 14.9236 0.0016942 13.0275 0.0016942 11.1314C0.0016942 10.4665 0.305425 10.0158 0.98889 10.0079C1.67179 9.99976 1.99579 10.4383 1.99945 11.1063C2.00452 12.1042 2.00086 13.1024 2.00114 14.1Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }