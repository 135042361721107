<template>
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3125 0H1.68751C0.75696 0 0 0.784995 0 1.75001V12.25C0 13.215 0.75696 14 1.68751 14H16.3125C17.243 14 18 13.215 18 12.25V1.75001C18 0.784995 17.243 0 16.3125 0ZM16.3125 1.16666C16.3889 1.16666 16.4616 1.1831 16.5281 1.21182L9 7.97811L1.47188 1.21182C1.53834 1.18314 1.61105 1.16666 1.68747 1.16666H16.3125ZM16.3125 12.8333H1.68751C1.37715 12.8333 1.125 12.5718 1.125 12.25V2.44441L8.6314 9.1909C8.73743 9.28602 8.86872 9.33332 9 9.33332C9.13128 9.33332 9.26256 9.28606 9.3686 9.1909L16.875 2.44441V12.25C16.875 12.5718 16.6228 12.8333 16.3125 12.8333Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      required: false,
      default: "#A0A0A0"
    }
  }
};
</script>
