var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "width": "12px",
      "height": "12px"
    },
    attrs: {
      "width": "7",
      "height": "9",
      "viewBox": "0 0 7 9",
      "fill": "inherit",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M3.99596 4.53395C3.99596 5.7813 3.99146 7.02878 3.99891 8.27598C4.00116 8.64977 3.92452 8.9725 3.48324 8.96589C3.07627 8.95984 2.99358 8.65567 2.99414 8.29905C2.99935 5.75444 2.99935 3.20983 2.99414 0.665218C2.99344 0.30789 3.07796 0.00470244 3.48493 6.18163e-05C3.92621 -0.00514131 4.00074 0.318859 3.99877 0.691937C3.99146 1.97261 3.99596 3.25328 3.99596 4.53395Z",
      "fill": ""
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M5.99256 5.93145C5.99256 5.16674 5.99777 4.40202 5.99031 3.6373C5.9868 3.27252 6.10028 2.99014 6.50514 2.99619C6.87794 3.00181 6.9948 3.26731 6.99395 3.61353C6.98945 5.19275 6.98945 6.77239 6.99381 8.35189C6.99494 8.69839 6.87681 8.96305 6.50402 8.96783C6.09916 8.97303 5.9868 8.68981 5.99045 8.32574C5.99777 7.52783 5.99256 6.7295 5.99256 5.93145Z",
      "fill": ""
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M0.999706 7.03651C0.999846 7.4851 0.997878 7.93398 1.00055 8.38257C1.00238 8.73582 0.852331 8.9777 0.481784 8.96841C0.140487 8.95956 -0.000700513 8.7257 2.61231e-06 8.39523C0.00211199 7.44798 0.000846368 6.50073 0.000846368 5.55348C0.000846368 5.22132 0.152581 4.99618 0.494018 4.99224C0.835175 4.98817 0.997034 5.20726 0.998862 5.54096C1.00139 6.03948 0.999565 6.53813 0.999706 7.03651Z",
      "fill": ""
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }