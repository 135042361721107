<template>
  <svg
    style="height:11px; width:15px;"
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1476 4.67435C13.191 4.4383 13.2129 4.19754 13.2129 3.95508C13.2129 1.77413 11.4388 0 9.25781 0C7.86562 0 6.57519 0.741592 5.86623 1.92003C4.90922 1.60978 3.9273 1.74281 3.11836 2.30027C2.30941 2.8573 1.8266 3.73922 1.78755 4.70353C0.724102 5.20863 0 6.28409 0 7.4707C0 7.67669 0.0498926 7.87755 0.0880664 8.07237L0.0885059 8.07281C0.372627 9.5066 1.64162 10.5469 3.10547 10.5469H11.8945C13.5906 10.5469 15 9.16673 15 7.4707C15 6.27123 14.266 5.19146 13.1476 4.67435Z"
      fill="#C1C1C1"
    />
  </svg>
</template>

<script>
export default {
  name: "CloudyWeatherIcon"
};
</script>

<style lang="scss" scoped></style>
