<template>
  <svg
    width="4"
    height="9"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0137 1.5293L5.50722 5.48279L1.00076 1.5293"
      stroke="#7367F0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      required: false,
      default: "4"
    },
    height: {
      type: String,
      required: false,
      default: "9"
    }
  }
};
</script>
