import Vue from "vue";
import dayjs from "@/plugins/dayjs";

export function toCurrency(value) {
  if (!Number.isInteger(value)) return value;

  let currencyReplaced = false;
  return new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: "JPY"
  })
    .formatToParts(value)
    .map((item, idx, arr) => {
      if (
        (item.type === "currency" || item.type === "literal") &&
        currencyReplaced
      )
        return "";
      const nextCurrency =
        arr[idx + 1] && arr[idx + 1].type === "currency" && arr[idx + 1].value;
      if (item.type === "minusSign" && nextCurrency && !currencyReplaced) {
        currencyReplaced = true;
        return `${nextCurrency}${item.value}`;
      }
      return `${item.value}`;
    })
    .join("");
}

export function formatThousands(value) {
  if (typeof value !== "number") {
    return value;
  }

  return value.toLocaleString("jp");
}

export function formatDate(value, format = "YYYY/MM/DD") {
  return dayjs(value).format(format);
}

const filters = { toCurrency, formatThousands, formatDate };

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
