import axios from "@/services/api";
import {
  FETCH_INVENTORY_MANAGEMENT,
  FETCH_INVENTORY_MANAGEMENT_SUCCESS,
  UPDATE_INVENTORY_MANAGEMENT,
  CREATE_INVENTORY_MANAGEMENT
} from "@/store/actions/inventorymanagement";

function initialState() {
  return {
    status: "",
    inventories: []
  };
}

const state = initialState();

const actions = {
  [FETCH_INVENTORY_MANAGEMENT]: ({ commit, dispatch }, params) => {
    const { shop_id } = params;
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${shop_id}/inventories`, { params })
        .then(response => {
          commit(FETCH_INVENTORY_MANAGEMENT_SUCCESS, response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [UPDATE_INVENTORY_MANAGEMENT]: ({ dispatch }, params) => {
    const { shop_id, id } = params;
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/admin/shops/${shop_id}/inventories/${id}`, params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [CREATE_INVENTORY_MANAGEMENT]: ({ dispatch }, params) => {
    const { shop_id } = params;
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/admin/shops/${shop_id}/inventories`, params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [FETCH_INVENTORY_MANAGEMENT_SUCCESS]: (state, params) => {
    state.inventories = params.data.inventory;
  }
};

const getters = {
  getInventories: state => state.inventories
};

export default {
  state,
  getters,
  actions,
  mutations
};
