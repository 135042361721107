<template>
  <v-dialog
    persistent
    max-width="400"
    :value="getModalVisibility"
    @keydown.esc="hideModal"
  >
    <v-card>
      <v-card-title class="headline">
        {{ getModalTitle }}
      </v-card-title>

      <v-card-text class="text-center">
        {{ getModalText }}
      </v-card-text>

      <v-card-text class="text-center">
        <v-btn
          :color="getModalAction.cancel.color"
          text
          @click="hideModal"
          :disabled="getModalLoading"
        >
          {{ getModalAction.cancel.text }}
        </v-btn>
        <v-btn
          text
          :color="getModalAction.process.color"
          v-if="getModalAction.function !== null"
          :loading="getModalLoading"
          @click="executeAction()"
        >
          {{ getModalAction.process.text }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "Action",
  computed: {
    ...mapGetters([
      "getModalTitle",
      "getModalText",
      "getModalVisibility",
      "getModalAction",
      "getModalLoading"
    ])
  },
  methods: {
    ...mapMutations(["hideModal", "startModalLoading"]),
    executeAction() {
      this.getModalAction.function();
      this.startModalLoading();
    }
  }
};
</script>

<style scoped></style>
