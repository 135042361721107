var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "width": "15px",
      "height": "15px"
    },
    attrs: {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12.623 0.0494985C13.5029 0.101998 14.2967 0.636842 14.7458 1.6163C15.2044 2.61731 15.0387 3.58247 14.2758 4.36715C12.601 6.09028 10.8894 7.77801 9.19131 9.47864C7.85233 10.8197 6.52014 12.1676 5.16334 13.4904C4.91022 13.7375 4.5774 13.954 4.24225 14.056C3.16319 14.3846 2.06772 14.659 0.975529 14.9429C0.26467 15.1275 -0.0859552 14.7884 0.0966229 14.0738C0.387717 12.9361 0.690764 11.8011 1.02545 10.6756C1.10608 10.4044 1.291 10.1375 1.49326 9.93379C4.45928 6.95161 7.43701 3.98161 10.4089 1.00551C10.9648 0.448873 11.5836 0.0394203 12.623 0.0494985ZM1.48436 13.5495C2.73944 13.1438 3.8942 13.1332 4.80475 12.1915C7.48811 9.41676 10.2483 6.71629 12.9755 3.98419C13.171 3.78872 13.3824 3.59419 13.5216 3.36075C13.8352 2.83481 13.7965 2.2927 13.4302 1.80567C13.0484 1.29778 12.4948 1.18622 11.9401 1.39598C11.6119 1.51997 11.3281 1.80426 11.0694 2.06137C8.36561 4.752 5.7085 7.4909 2.95389 10.1274C1.9674 11.0712 1.92733 12.2597 1.48436 13.5495Z",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }