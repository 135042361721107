var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M7.84041 8.00308C5.66791 7.97783 3.90966 6.19208 3.91016 4.01108C3.91066 1.76583 5.74541 -0.0239156 8.00091 0.0203344C10.1669 0.0628344 11.9202 1.87983 11.8914 4.05233C11.8622 6.27333 10.0624 8.02858 7.84041 8.00308ZM10.2867 3.98658C10.2717 2.66858 9.20891 1.61633 7.89566 1.61883C6.58141 1.62158 5.52291 2.67833 5.51441 3.99658C5.50566 5.34658 6.59641 6.42658 7.94541 6.40308C9.25816 6.38033 10.3019 5.30333 10.2867 3.98658Z",
      "fill": "#ABABAB"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M7.92135 9.6001C8.8241 9.6001 9.72735 9.58385 10.6299 9.60335C12.9744 9.65385 14.6216 11.2933 14.6814 13.6251C14.6944 14.1296 14.6906 14.6343 14.6799 15.1388C14.6691 15.6486 14.3649 15.9536 13.8884 15.9548C13.4126 15.9561 13.1046 15.6556 13.0924 15.1443C13.0804 14.6668 13.0964 14.1883 13.0849 13.7103C13.0496 12.2001 12.0811 11.2148 10.5869 11.2016C8.8076 11.1858 7.0281 11.1876 5.24885 11.2006C3.70485 11.2121 2.74185 12.1928 2.7156 13.7543C2.7081 14.2056 2.71485 14.6568 2.71185 15.1083C2.7086 15.6171 2.42285 15.9401 1.9476 15.9541C1.42785 15.9696 1.11485 15.6418 1.1206 15.0956C1.1286 14.3528 1.0696 13.5958 1.1921 12.8706C1.50635 11.0063 3.0591 9.69035 4.97335 9.61385C5.95435 9.57485 6.9381 9.6071 7.92085 9.6071C7.92135 9.60435 7.92135 9.6021 7.92135 9.6001Z",
      "fill": "#ABABAB"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }