<template>
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.48024 5.63133e-06C8.51383 -0.00429124 10.9802 2.45094 10.9844 5.48007C10.9883 8.51383 8.53325 10.9801 5.50413 10.9844C2.47071 10.9887 0.00413019 8.53326 5.19052e-06 5.5043C-0.00411981 2.47071 2.45094 0.00430251 5.48024 5.63133e-06ZM5.52097 0.998771C3.04666 0.988287 1.01819 2.97963 0.998943 5.43847C0.979177 7.93822 2.97911 9.97529 5.4634 9.9856C7.93771 9.99626 9.966 8.00474 9.9856 5.54607C10.0054 3.04632 8.00525 1.00908 5.52097 0.998771Z"
      fill="#525252"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.8455 4.99297C5.65503 4.99297 6.46422 4.99847 7.27357 4.99039C7.64276 4.98678 7.97775 5.05485 7.97431 5.49657C7.9707 5.93914 7.63039 5.99758 7.26429 5.99363C6.45665 5.98572 5.64832 5.99122 4.7685 6.10947C5.08732 6.41163 5.4089 6.71104 5.72429 7.01663C5.95684 7.24213 6.12407 7.50424 5.85801 7.7966C5.58078 8.10168 5.29237 7.98927 5.03679 7.73816C4.40825 7.12044 3.77626 6.50668 3.15837 5.87899C2.90829 5.62496 2.92342 5.33896 3.17625 5.08613C3.79929 4.46342 4.42836 3.84674 5.0545 3.22696C5.29529 2.98908 5.55723 2.87461 5.83257 3.15597C6.1062 3.43544 5.99397 3.70494 5.75162 3.93714C5.42764 4.24721 5.09351 4.54661 4.7642 4.85066C4.79118 4.89827 4.81817 4.94571 4.8455 4.99297Z"
      fill="#525252"
    />
  </svg>
</template>

<script>
export default {
  name: "GoBackIcon"
};
</script>

<style scoped></style>
