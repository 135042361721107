<template>
  <svg
    style="width:15px; height:3px;"
    width="15"
    height="3"
    viewBox="0 0 15 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="1.5" cy="1.5" r="1.5" fill="#565656" />
    <circle cx="7.5" cy="1.5" r="1.5" fill="#565656" />
    <circle cx="13.5" cy="1.5" r="1.5" fill="#565656" />
  </svg>
</template>

<script>
export default {
  name: "HorizontalKebabIcon"
};
</script>

<style lang="scss" scoped></style>
