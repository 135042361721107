export const GET_ALL_USERS = "GET_ALL_USERS";
export const USER_GET = "USER_GET";
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const USER_PASSWORD_RESET_POST = "USER_PASSWORD_RESET_POST";
export const USERS_REQUEST = "USERS_REQUEST";

export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const USER_PASSWORD_RESET_POST_SUCCESS =
  "USER_PASSWORD_RESET_POST_SUCCESS";
export const USER_PASSWORD_RESET_POST_ERROR = "USER_PASSWORD_RESET_POST_ERROR";

export const USER_ADMINS_GET = "USER_ADMINS_GET";
export const USER_ADMINS_GET_SUCCESS = "USER_ADMINS_GET_SUCCESS";
export const USER_ADMINS_GET_ERROR = "USER_ADMINS_GET_ERROR";
export const USER_ADMINS_CREATE = "USER_ADMINS_CREATE";
export const USER_ADMINS_CREATE_SUCCESS = "USER_ADMINS_CREATE_SUCCESS";
export const USER_ADMINS_CREATE_ERROR = "USER_ADMINS_CREATE_ERROR";
export const USER_ADMINS_EDIT = "USER_ADMINS_EDIT";
export const USER_ADMINS_EDIT_SUCCESS = "USER_ADMINS_EDIT_SUCCESS";
export const USER_ADMINS_EDIT_ERROR = "USER_ADMINS_EDIT_ERROR";
export const USER_ADMINS_PASSWORD_RESET = "USER_ADMINS_PASSWORD_RESET";
export const USER_ADMINS_PASSWORD_RESET_SUCCESS =
  "USER_ADMINS_PASSWORD_RESET_SUCCESS";
export const USER_ADMINS_PASSWORD_RESET_ERROR =
  "USER_ADMINS_PASSWORD_RESET_ERROR";
export const USER_ADMINS_DELETE = "USER_ADMINS_DELETE";
export const USER_ADMINS_DELETE_SUCCESS = "USER_ADMINS_DELETE_SUCCESS";
export const USER_ADMINS_DELETE_ERROR = "USER_ADMINS_DELETE_ERROR";

export const NEXT_USER_NUMBER = "NEXT_USER_NUMBER";
export const NEXT_USER_NUMBER_SUCCESS = "NEXT_USER_NUMBER_SUCCESS";
export const NEXT_USER_NUMBER_ERROR = "NEXT_USER_NUMBER_ERROR";
