import axios from "@/services/api";
import {
  GOAL_REQUEST,
  GOAL_GET_ALL,
  GOAL_UPDATE,
  GOAL_UPDATE_SUCCESS
} from "@/store/actions/goal";

function initialState() {
  return {
    all_goals: [],
    shop_goal_business_year: {}
  };
}

const state = initialState();

const getters = {
  getAllGoal: state => state.all_goals,
  getShopGoalBusinessYear: state => state.shop_goal_business_year
};

const actions = {
  [GOAL_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("GOAL_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${params.id}/goals`, { params })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [GOAL_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("GOAL_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/admin/shops/${params.shop_id}/goals`, params)
        .then(response => {
          commit("GOAL_UPDATE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [GOAL_REQUEST]: state => {
    state.status = "loading";
  },

  [GOAL_UPDATE_SUCCESS]: (state, params) => {
    state.status = "success";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
