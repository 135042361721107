import axios from "@/services/api";
import {
  SHARED_ACCOUNT_CREATE,
  SHARED_ACCOUNT_UPDATE,
  SHARED_ACCOUNT_DELETE
} from "@/store/actions/shared_account";

function initialState() {
  return {};
}

const state = initialState();

const getters = {};

const actions = {
  [SHARED_ACCOUNT_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/admin/shops/${params.shop_id}/shared-accounts`, params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [SHARED_ACCOUNT_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(
          `/admin/shops/${params.shop_id}/shared-accounts/${params.id}`,
          params
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [SHARED_ACCOUNT_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(`/admin/shops/${params.shop_id}/shared-accounts/${params.id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
