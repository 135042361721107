<template>
  <v-navigation-drawer
    app
    clipped
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"
    :class="{ 'drawer-mini': !DRAWER_STATE }"
  >
    <v-list nav dense>
      <template v-for="(item, i) in items">
        <v-row v-if="item.heading" :key="item.heading" align="center">
          <v-col cols="6">
            <span
              class="text-body-1 subheader pl-8"
              :class="item.heading && DRAWER_STATE ? 'show ' : 'hide'"
            >
              {{ item.heading }}
            </span>
          </v-col>
          <v-col cols="6" class="text-center"> </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-1"
        ></v-divider>
        <v-list-group
          color="primary"
          v-else-if="item.children"
          :key="`${item.title}-${i}`"
          v-model="item.model"
        >
          <template v-slot:prependIcon>
            <v-icon size="18">{{ item.icon }}</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="grey-darken-1--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="child in item.children">
            <v-subheader v-if="child.subheader !== ''" :key="child.subheader">{{
              child.subheader
            }}</v-subheader>
            <v-list-item
              v-for="item in child.items"
              :key="item.title"
              :to="item.link"
              link
              exact
            >
              <v-list-item-action>
                <v-icon size="8">$customChevronRight</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="grey-darken-1--text">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
        <v-list-item
          color="primary"
          v-else
          :key="item.text"
          :to="item.link === '#' ? null : item.link"
          link
        >
          <v-list-item-action>
            <v-icon size="20" :color="item.color ? item.color : ''">{{
              item.icon
            }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="grey-darken-1--text" link>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  props: {
    source: String
  },
  beforeMount() {
    this.$store.dispatch("STATS_REQUEST");
    this.$store.dispatch("MASTER_GET_ALL");
    this.$store.dispatch("PREFECTURES_GET_ALL");
  },
  data() {
    return {
      sidebarWidth: 240,
      sidebarMinWidth: 76,
      numberOfStores: 43
    };
  },
  computed: {
    ...mapState(["drawer"]),
    ...mapGetters(["getMasterData"]),

    items() {
      return [
        {
          title: this.$t("sidebar_menu_store", {
            count: this.getMasterData.shops_count
          }),
          icon: "$store",
          // link: '/icons',
          model: false,
          children: [
            {
              subheader: "",
              items: [
                {
                  title: this.$t("sidebar_menu_store_list", {
                    count: this.getMasterData.shops_count
                  }),
                  link: {
                    name: "Shops"
                  }
                },
                {
                  title: this.$t("sidebar_menu_store_signup"),
                  link: {
                    name: "ShopsCreate"
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.$t("sidebar_menu_user", {
            count: this.getMasterData.users_count
          }),
          icon: "$users",
          // link: '/icons',
          model: false,
          children: [
            {
              subheader: "",
              items: [
                {
                  title: this.$t("sidebar_menu_user_list"),
                  link: {
                    name: "UserList"
                  }
                },
                {
                  title: this.$t("sidebar_menu_user_create"),
                  link: {
                    name: "UserCreate"
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.$t("sidebar_menu_notification"),
          icon: "$bell",
          // link: '/icons',
          model: false,
          children: [
            {
              subheader: "",
              items: [
                {
                  title: this.$t("sidebar_menu_notification_list"),
                  link: {
                    name: "NotificationList"
                  }
                },
                {
                  title: this.$t("sidebar_menu_notification_drafts"),
                  link: {
                    name: "NotificationDraft"
                  }
                },
                {
                  title: this.$t("sidebar_menu_notification_link_post"),
                  link: {
                    name: "NotificationLinkPost"
                  }
                },
                {
                  title: this.$t("sidebar_menu_notification_category_tag"),
                  link: {
                    name: "NotificationLinkColumn"
                  }
                }
              ]
            }
          ]
        },
        {
          title: this.$t("sidebar_menu_setting"),
          icon: "$gear",
          // link: '/icons',
          model: false,
          children: [
            {
              subheader: "",
              items: [
                {
                  title: this.$t("sidebar_menu_admin_users", {
                    count: this.getMasterData.admin_users_count
                  }),
                  link: {
                    name: "AdminUsers"
                  }
                },
                {
                  title: this.$t("sidebar_menu_faq"),
                  link: {
                    name: "Faq"
                  }
                }
              ]
            }
          ]
        }
      ];
    },

    DRAWER_STATE: {
      get() {
        return this.drawer;
      },
      set(newValue) {
        if (newValue === this.drawer) return;
        this.TOGGLE_DRAWER();
      }
    }
  },
  methods: {
    ...mapActions(["TOGGLE_DRAWER"])
  }
};
</script>

<style src="./Sidebar.scss" lang="scss" />
