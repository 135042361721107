<template>
  <svg
    style="height:15px; width:15px;"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49958 3.74219C5.42772 3.74219 3.74219 5.42805 3.74219 7.49991C3.74219 9.57178 5.42772 11.2576 7.49958 11.2576C9.57111 11.2576 11.257 9.57211 11.257 7.49991C11.257 5.42772 9.57111 3.74219 7.49958 3.74219Z"
      fill="#C1C1C1"
    />
    <path
      d="M7.50267 2.63891C7.09773 2.63891 6.76953 2.31071 6.76953 1.9061V0.733142C6.76953 0.328203 7.09773 0 7.50267 0C7.90761 0 8.23582 0.328203 8.23582 0.733142V1.9061C8.23582 2.31071 7.90728 2.63891 7.50267 2.63891Z"
      fill="#C1C1C1"
    />
    <path
      d="M7.50267 12.3633C7.09773 12.3633 6.76953 12.6915 6.76953 13.0964V14.2691C6.76953 14.6743 7.09773 15.0025 7.50267 15.0025C7.90761 15.0025 8.23582 14.6743 8.23582 14.2691V13.0964C8.23582 12.6915 7.90728 12.3633 7.50267 12.3633Z"
      fill="#C1C1C1"
    />
    <path
      d="M10.9372 4.06317C10.6512 3.77683 10.6512 3.31276 10.9372 3.02641L11.7666 2.19693C12.0527 1.91092 12.5171 1.91092 12.8034 2.19693C13.0898 2.48328 13.0898 2.94768 12.8034 3.2337L11.9739 4.06317C11.6879 4.34952 11.2238 4.34952 10.9372 4.06317Z"
      fill="#C1C1C1"
    />
    <path
      d="M4.06525 10.9396C3.7789 10.6529 3.31483 10.6529 3.02849 10.9396L2.19901 11.7688C1.913 12.0548 1.91266 12.5195 2.19901 12.8055C2.48536 13.0915 2.94976 13.0915 3.23577 12.8055L4.06525 11.9757C4.3516 11.6897 4.3516 11.2253 4.06525 10.9396Z"
      fill="#C1C1C1"
    />
    <path
      d="M12.3594 7.50072C12.3594 7.09578 12.6876 6.76758 13.0925 6.76758H14.2655C14.6704 6.76758 14.9986 7.09578 14.9986 7.50072C14.9986 7.90566 14.6704 8.23353 14.2655 8.23353H13.0925C12.6876 8.23353 12.3594 7.90566 12.3594 7.50072Z"
      fill="#C1C1C1"
    />
    <path
      d="M2.63891 7.50072C2.63891 7.09578 2.31071 6.76758 1.90577 6.76758H0.733142C0.328203 6.76758 0 7.09578 0 7.50072C0 7.90566 0.328203 8.23353 0.733142 8.23353H1.9061C2.31071 8.23353 2.63891 7.90566 2.63891 7.50072Z"
      fill="#C1C1C1"
    />
    <path
      d="M10.9374 10.9391C11.2238 10.6531 11.6882 10.6531 11.9742 10.9391L12.8037 11.7686C13.09 12.0543 13.09 12.519 12.8037 12.805C12.5173 13.091 12.0532 13.091 11.7669 12.805L10.9374 11.9756C10.6511 11.6892 10.6511 11.2251 10.9374 10.9391Z"
      fill="#C1C1C1"
    />
    <path
      d="M4.06538 4.06309C4.35172 3.77674 4.35172 3.31267 4.06538 3.02633L3.2359 2.19718C2.94955 1.91084 2.48548 1.91084 2.19914 2.19718C1.91279 2.4832 1.91279 2.9476 2.19914 3.23361L3.02861 4.06309C3.31496 4.34977 3.77903 4.34977 4.06538 4.06309Z"
      fill="#C1C1C1"
    />
  </svg>
</template>

<script>
export default {
  name: "SunnyWeatherIcon"
};
</script>

<style lang="scss" scoped></style>
