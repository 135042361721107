var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    staticClass: "main-header",
    attrs: {
      "height": "64",
      "fixed": "",
      "color": "#0C1B73"
    }
  }, [_c('v-toolbar-title', [_c('router-link', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "to": {
        name: 'Shops'
      }
    }
  }, [_c('img', {
    staticClass: "toolbar-image",
    attrs: {
      "src": require("@/assets/images/cp-logo-white.png")
    }
  })])], 1), _c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.TOGGLE_DRAWER($event);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "28",
      "color": "white"
    }
  }, [_vm._v("mdi-menu")])], 1), _c('v-spacer'), _c('v-menu', {
    attrs: {
      "min-width": "180",
      "offset-y": "",
      "bottom": "",
      "left": "",
      "nudge-bottom": "10"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({
          staticClass: "mr-10 mouse-pointer font-18px white--text",
          attrs: {
            "depressed": ""
          }
        }, 'div', attrs, false), on), [_vm._v(" " + _vm._s(_vm.user ? "".concat(_vm.user.surname, " ").concat(_vm.user.name) : "Admin") + " さん ")])];
      }
    }])
  }, [_c('v-list', [_c('div', {
    staticClass: "text-h5 grey--text text--darken-3 px-4 pt-4"
  }, [_vm._v(" " + _vm._s(_vm.user ? "".concat(_vm.user.surname, " ").concat(_vm.user.name) : "") + " ")]), _c('div', {
    staticClass: "subtitle-2 primary--text font-weight-regular px-4"
  }, [_vm._v(" " + _vm._s(_vm.user ? _vm.user.email : "") + " ")]), _c('v-list-item-group', {
    attrs: {
      "color": "primary"
    }
  }, _vm._l(_vm.account, function (item, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-icon', {
      staticClass: "mr-4"
    }, [_c('v-icon', {
      attrs: {
        "color": item.color
      },
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      attrs: {
        "color": _vm.config.light.textColor
      },
      domProps: {
        "textContent": _vm._s(item.text)
      }
    })], 1)], 1);
  }), 1), _c('div', {
    staticClass: "d-flex justify-center my-3"
  }, [_c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "width": "80%",
      "large": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": _vm.logOut
    }
  }, [_vm._v(_vm._s(_vm.$t("sign_out")) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }