var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M3.01041 3.00912C-0.303864 6.3234 -0.303864 11.7157 3.01041 15.0299C6.32468 18.3442 11.717 18.3442 15.0312 15.0299C18.3455 11.7157 18.3455 6.3234 15.0312 3.00912C11.717 -0.305148 6.32468 -0.305148 3.01041 3.00912ZM12.1512 6.89081L10.0225 9.01953L12.1512 11.1482C12.4277 11.4248 12.4277 11.8734 12.1512 12.1499C11.8747 12.4264 11.426 12.4264 11.1495 12.1499L9.02082 10.0212L6.8921 12.1499C6.61559 12.4264 6.16694 12.4264 5.89043 12.1499C5.61391 11.8734 5.61391 11.4248 5.89043 11.1482L8.01914 9.01953L5.89043 6.89081C5.61391 6.6143 5.61391 6.16565 5.89043 5.88914C6.16694 5.61263 6.61559 5.61263 6.8921 5.88914L9.02082 8.01786L11.1495 5.88914C11.426 5.61263 11.8747 5.61263 12.1512 5.88914C12.4277 6.16565 12.4277 6.6143 12.1512 6.89081Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }