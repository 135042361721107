<template>
  <svg
    style="width: 6px; height: 13px;"
    width="6"
    height="13"
    viewBox="0 0 6 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.95312 11.0117L0.999637 6.50526L4.95313 1.99881"
      stroke="#7367F0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowLeftPurpleIcon"
};
</script>
