<template>
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.999023 4.95215L5.50548 0.99866L10.0119 4.95215"
      stroke="#525252"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowUpIcon"
};
</script>

<style lang="scss" scoped></style>
