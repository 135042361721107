export const SHOP_REQUEST = "SHOP_REQUEST";

export const SHOP_GET_ALL = "SHOP_GET_ALL";
export const SHOP_GET_ALL_SUCCESS = "SHOP_GET_ALL_SUCCESS";

export const SHOP_REGISTER = "SHOP_REGISTER";
export const SHOP_REGISTER_SUCCESS = "SHOP_REGISTER_SUCCESS";

export const SHOP_DELETE = "SHOP_DELETE";
export const SHOP_DELETE_SUCCESS = "SHOP_DELETE_SUCCESS";

export const SHOP_GET = "SHOP_GET";
export const SHOP_GET_SUCCESS = "SHOP_GET_SUCCESS";

export const SHOP_UPDATE = "SHOP_UPDATE";
export const SHOP_UPDATE_SUCCESS = "SHOP_UPDATE_SUCCESS";

export const SHOP_INVITE_USER = "SHOP_INVITE_USER";
export const SHOP_INVITE_USER_SUCCESS = "SHOP_INVITE_USER_SUCCESS";

export const SHOP_USER_GET = "SHOP_USER_GET";
export const SHOP_USER_GET_SUCCESS = "SHOP_USER_GET_SUCCESS";

export const SHOP_USER_UPDATE = "SHOP_USER_UPDATE";
export const SHOP_USER_UPDATE_SUCCESS = "SHOP_USER_UPDATE_SUCCESS";

export const SHOP_USER_DELETE = "SHOP_USER_DELETE";
export const SHOP_USER_DELETE_SUCCESS = "SHOP_USER_DELETE_SUCCESS";

export const SHOP_DAILY_REPORT_ALL = "SHOP_DAILY_REPORT_ALL";
export const SHOP_DAILY_REPORT_ALL_SUCCESS = "SHOP_DAILY_REPORT_ALL_SUCCESS";

export const SHOP_MONTHLY_REPORT_ALL = "SHOP_MONTHLY_REPORT_ALL";
export const SHOP_MONTHLY_REPORT_ALL_SUCCESS = "SHOP_MONTHLY_REPORT_ALL_SUCCESS";

export const SHOP_DAILY_REPORT = "SHOP_DAILY_REPORT";
export const SHOP_DAILY_REPORT_SUCCESS = "SHOP_DAILY_REPORT_SUCCESS";

export const SHOP_MONTHLY_REPORT = "SHOP_MONTHLY_REPORT";
export const SHOP_MONTHLY_REPORT_SUCCESS = "SHOP_MONTHLY_REPORT_SUCCESS";
