import axios from "@/services/api";
import {
  ACCOUNTS_PAYABLE_REQUEST,
  ACCOUNTS_PAYABLE_GET_ALL,
  ACCOUNTS_PAYABLE_GET_ALL_SUCCESS,
  ACCOUNTS_PAYABLE_GET,
  ACCOUNTS_PAYABLE_GET_SUCCESS,
  ACCOUNTS_PAYABLE_REGISTER,
  ACCOUNTS_PAYABLE_REGISTER_SUCCESS,
  ACCOUNTS_PAYABLE_UPDATE,
  ACCOUNTS_PAYABLE_UPDATE_SUCCESS,
  ACCOUNTS_PAYABLE_DELETE,
  ACCOUNTS_PAYABLE_DELETE_SUCCESS
} from "@/store/actions/accounts";

function initialState() {
  return {
    accounts_payables: [],
    accounts_payable: {},
    pagination: {}
  };
}

const state = initialState();

const getters = {
  getAccountsPayables: state => state.accounts_payables,
  getAccountsPayable: state => state.accounts_payable,
  getAccountsPayablesPagination: state => state.pagination
};

const actions = {
  [ACCOUNTS_PAYABLE_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("ACCOUNTS_PAYABLE_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${params.id}/accounts-payables`, {
          params: { ...params.param }
        })
        .then(response => {
          commit("ACCOUNTS_PAYABLE_GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [ACCOUNTS_PAYABLE_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("ACCOUNTS_PAYABLE_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(
          `/admin/shops/${params.id}/accounts-payables/${params.accounts_payable_id}`,
          {
            params: { ...params.param }
          }
        )
        .then(response => {
          commit("ACCOUNTS_PAYABLE_GET_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [ACCOUNTS_PAYABLE_REGISTER]: ({ commit, dispatch }, { params, shop_id }) => {
    return new Promise((resolve, reject) => {
      commit("ACCOUNTS_PAYABLE_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/admin/shops/${shop_id}/accounts-payables`, params)
        .then(response => {
          commit("ACCOUNTS_PAYABLE_REGISTER_SUCCESS", response.data);
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [ACCOUNTS_PAYABLE_UPDATE]: (
    { commit, dispatch },
    { params, shop_id, accounts_payable_id }
  ) => {
    return new Promise((resolve, reject) => {
      commit("ACCOUNTS_PAYABLE_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(
          `/admin/shops/${shop_id}/accounts-payables/${accounts_payable_id}`,
          params
        )
        .then(response => {
          commit("ACCOUNTS_PAYABLE_UPDATE_SUCCESS", response.data);
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [ACCOUNTS_PAYABLE_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("ACCOUNTS_PAYABLE_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(
          `/admin/shops/${params.shop_id}/accounts-payables/${params.accounts_payable_id}`,
          {
            params: { ...params.param }
          }
        )
        .then(response => {
          commit("ACCOUNTS_PAYABLE_DELETE_SUCCESS", response.data);
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [ACCOUNTS_PAYABLE_REQUEST]: state => {
    state.status = "loading";
  },

  [ACCOUNTS_PAYABLE_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.accounts_payables = params.data.accounts_payable;
    if (params.paginate) {
      state.pagination = params.paginate;
    }
  },

  [ACCOUNTS_PAYABLE_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.accounts_payable = params.data.accounts_payable;
  },

  [ACCOUNTS_PAYABLE_REGISTER_SUCCESS]: (state, params) => {
    state.status = "success";
  },

  [ACCOUNTS_PAYABLE_UPDATE_SUCCESS]: (state, params) => {
    state.status = "success";
  },

  [ACCOUNTS_PAYABLE_DELETE_SUCCESS]: (state, params) => {
    state.status = "success";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
