import axios from "@/services/api";
import "@/store/actions/notification/notifications";
import {
  GET_ALL_NOTIFICATIONS,
  NOTIFICATION_GET,
  GET_ALL_NOTIFICATIONS_ERROR,
  NOTIFICATIONS_REQUEST,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_ERROR,
  EDIT_NOTIFICATION_SUCCESS,
  EDIT_NOTIFICATION_ERROR,
  EDIT_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_ERROR,
  DELETE_NOTIFICATION,
  GET_ALL_NOTIFICATION_TYPES_SUCCESS,
  GET_ALL_NOTIFICATION_TYPES_ERROR,
  GET_ALL_NOTIFICATION_TYPES,
  GET_ALL_NOTIFICATION_USER_VIEWS,
  GET_ALL_NOTIFICATION_USER_VIEWS_SUCCESS,
  GET_ALL_NOTIFICATION_USER_VIEWS_ERROR
} from "@/store/actions/notification/notifications";

function initialState() {
  return {
    status: "",
    pagination: {},
    notifications: [],
    notificationTypes: [],
    notification: {},
    message: "",
    errors: {},
    user_view_list: []
  };
}

const state = initialState();

const getters = {
  allNotifications: state => state.notifications,
  allNotificationTypes: state => state.notificationTypes,
  singleNotification: state => state.notification,
  notificationPagination: state => state.pagination,
  notificationViews: state => state.user_view_list
};

const actions = {
  [GET_ALL_NOTIFICATIONS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/admin/notification/notifications", {
          params: params
        })
        .then(response => {
          let data = response.data;

          commit("GET_ALL_NOTIFICATIONS_SUCCESS", {
            notification: data.data.notification,
            pagination: data.paginate
          });

          resolve(response);
        })
        .catch(error => {
          commit("GET_ALL_NOTIFICATIONS_ERROR", { error: error });

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [NOTIFICATION_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/notification/notifications/${params.id}`)
        .then(response => {
          let data = response.data;

          commit("GET_NOTIFICATION_SUCCESS", {
            notification: data.data.notification,
            pagination: data.paginate
          });

          resolve(response);
        })
        .catch(error => {
          commit("GET_NOTIFICATION_ERROR", { error: error });

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [ADD_NOTIFICATION]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/admin/notification/notifications`, params)
        .then(response => {
          commit("ADD_NOTIFICATION_SUCCESS", {
            message: response.data.data.message
          });
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });

          resolve(response);
        })
        .catch(error => {
          dispatch("ALERT", {
            show: true,
            text: error.data.error.message
          });
          if (error.status === 422) {
            commit("ADD_NOTIFICATION_ERROR", {
              message: error.data.error.message,
              errors: error.data.error.errors
            });
            reject(error);
          }

          commit("ADD_NOTIFICATION_ERROR", {
            message: error.response.data.message
          });
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [EDIT_NOTIFICATION]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/admin/notification/notifications/${params.id}`, params)
        .then(response => {
          commit("EDIT_NOTIFICATION_SUCCESS", {
            message: response.data.data.message
          });
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });

          resolve(response);
        })
        .catch(error => {
          dispatch("ALERT", {
            show: true,
            text: error.data.error.message
          });
          if (error.status === 422) {
            commit("EDIT_NOTIFICATION_ERROR", {
              message: error.data.error.message,
              errors: error.data.error.errors
            });
            reject(error);
          }

          commit("EDIT_NOTIFICATION_ERROR", {
            message: error.response.data.message
          });
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [DELETE_NOTIFICATION]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(`/admin/notification/notifications/${params.id}`, params)
        .then(response => {
          commit("DELETE_NOTIFICATION_SUCCESS", {
            message: response.data.data.message
          });
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });

          resolve(response);
        })
        .catch(error => {
          dispatch("ALERT", {
            show: true,
            text: error.data.error.message
          });
          if (error.status === 422) {
            commit("DELETE_NOTIFICATION_ERROR", {
              message: error.data.error.message,
              errors: error.data.error.errors
            });
            reject(error);
          }

          commit("DELETE_NOTIFICATION_ERROR", {
            message: error.response.data.message
          });
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [GET_ALL_NOTIFICATION_TYPES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/admin/notification/types", {
          params: params
        })
        .then(response => {
          let data = response.data;

          commit("GET_ALL_NOTIFICATION_TYPES_SUCCESS", {
            notificationTypes: data.data.types,
            pagination: data.paginate
          });

          resolve(response);
        })
        .catch(error => {
          commit("GET_ALL_NOTIFICATION_TYPES_ERROR", { error: error });

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [GET_ALL_NOTIFICATION_USER_VIEWS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/admin/notification-clicked-users", {
          params: params
        })
        .then(response => {
          let data = response.data;

          commit("GET_ALL_NOTIFICATION_USER_VIEWS_SUCCESS", {
            views: data.data.notificationClicksUserList
          });

          resolve(response);
        })
        .catch(error => {
          commit("GET_ALL_NOTIFICATION_USER_VIEWS_ERROR", { error: error });

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [NOTIFICATIONS_REQUEST]: state => {
    state.status = "loading";
  },
  [GET_ALL_NOTIFICATIONS_SUCCESS]: (state, params) => {
    state.status = "success";
    state.notifications = params.notification;
    state.pagination = params.pagination;
  },
  [GET_ALL_NOTIFICATIONS_ERROR]: state => {
    state.status = "error";
  },
  [GET_NOTIFICATION_SUCCESS]: (state, params) => {
    state.status = "success";
    state.notification = params.notification;
    state.pagination = params.pagination;
  },

  [GET_ALL_NOTIFICATION_TYPES_SUCCESS]: (state, params) => {
    state.status = "success";
    state.notificationTypes = params.notificationTypes;
    state.pagination = params.pagination;
  },
  [GET_ALL_NOTIFICATION_TYPES_ERROR]: state => {
    state.status = "error";
  },

  [ADD_NOTIFICATION_SUCCESS]: (state, params) => {
    state.status = "success";
    state.message = params.message;
  },
  [ADD_NOTIFICATION_ERROR]: (state, params) => {
    state.status = "error";
    state.message = params.message;
    state.errors = params.errors;
  },
  [EDIT_NOTIFICATION_SUCCESS]: (state, params) => {
    state.status = "success";
    state.message = params.message;
  },
  [EDIT_NOTIFICATION_ERROR]: (state, params) => {
    state.status = "error";
    state.message = params.message;
    state.errors = params.errors;
  },

  [DELETE_NOTIFICATION_SUCCESS]: (state, params) => {
    state.status = "success";
    state.message = params.message;
  },
  [DELETE_NOTIFICATION_ERROR]: (state, params) => {
    state.status = "error";
    state.message = params.message;
    state.errors = params.errors;
  },

  [GET_ALL_NOTIFICATION_USER_VIEWS_SUCCESS]: (state, params) => {
    state.status = "success";
    state.user_view_list = params.views;
  },
  [GET_ALL_NOTIFICATION_USER_VIEWS_ERROR]: (state, params) => {
    state.status = "error";
    state.message = params.message;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
