<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.96672 9.92472C2.23563 9.91831 0.0204717 7.69081 0.0234405 4.95362C0.026253 2.20081 2.26469 -0.0149725 5.02828 -0.000909995C7.75438 0.01284 9.95907 2.25003 9.94907 4.99268C9.93906 7.72159 7.70703 9.93112 4.96672 9.92472ZM4.98453 9.02112C7.22922 9.02175 9.04906 7.19846 9.04547 4.95222C9.04219 2.728 7.23172 0.910965 5.01047 0.902527C2.7611 0.893934 0.931565 2.70706 0.927034 4.94893C0.92219 7.19596 2.74032 9.02065 4.98453 9.02112Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.43731 5.86745C5.43731 6.15261 5.44075 6.43792 5.43637 6.72308C5.43137 7.02558 5.27918 7.21652 4.97137 7.21027C4.6884 7.20417 4.53903 7.02042 4.53653 6.7387C4.53137 6.15339 4.52996 5.56792 4.53731 4.98261C4.54059 4.70136 4.69481 4.5198 4.9784 4.51808C5.28637 4.51636 5.43356 4.71027 5.43684 5.01198C5.4395 5.29714 5.43731 5.58245 5.43731 5.86745Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.44387 3.17225C5.40106 3.44647 5.24981 3.616 4.9645 3.6024C4.70513 3.59006 4.54372 3.42818 4.54153 3.16709C4.53903 2.88381 4.70653 2.71272 4.9895 2.7124C5.27544 2.71209 5.41747 2.89115 5.44387 3.17225Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="10" height="10" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "InfoIcon"
};
</script>

<style scoped></style>
