export const ACCOUNTS_PAYABLE_REQUEST = "ACCOUNTS_PAYABLE_REQUEST";

export const ACCOUNTS_PAYABLE_GET_ALL = "ACCOUNTS_PAYABLE_GET_ALL";
export const ACCOUNTS_PAYABLE_GET_ALL_SUCCESS = "ACCOUNTS_PAYABLE_GET_ALL_SUCCESS";

export const ACCOUNTS_PAYABLE_GET = "ACCOUNTS_PAYABLE_GET";
export const ACCOUNTS_PAYABLE_GET_SUCCESS = "ACCOUNTS_PAYABLE_GET_SUCCESS";

export const ACCOUNTS_PAYABLE_REGISTER = "ACCOUNTS_PAYABLE_REGISTER";
export const ACCOUNTS_PAYABLE_REGISTER_SUCCESS = "ACCOUNTS_PAYABLE_REGISTER_SUCCESS";

export const ACCOUNTS_PAYABLE_UPDATE = "ACCOUNTS_PAYABLE_UPDATE";
export const ACCOUNTS_PAYABLE_UPDATE_SUCCESS = "ACCOUNTS_PAYABLE_UPDATE_SUCCESS";

export const ACCOUNTS_PAYABLE_DELETE = "ACCOUNTS_PAYABLE_DELETE";
export const ACCOUNTS_PAYABLE_DELETE_SUCCESS = "ACCOUNTS_PAYABLE_DELETE_SUCCESS";