var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "height": "12px"
    },
    attrs: {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('line', {
    attrs: {
      "y1": "5.5",
      "x2": "12",
      "y2": "5.5",
      "stroke": "#7367F0"
    }
  }), _c('line', {
    attrs: {
      "x1": "6.5",
      "y1": "2.18557e-08",
      "x2": "6.5",
      "y2": "12",
      "stroke": "#7367F0"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }