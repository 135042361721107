var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    staticClass: "pa-6"
  }, [_c('Header'), _c('Sidebar'), _c('Action'), _c('v-main', {
    staticClass: "content"
  }, [_c('router-view'), _c('Footer')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }