var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('svg', {
    attrs: {
      "width": "12",
      "height": "6",
      "viewBox": "0 0 12 6",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M10.0117 0.999023L5.50526 4.95251L0.998806 0.999023",
      "stroke": "#525252",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }