var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "25",
      "height": "25",
      "viewBox": "0 0 25 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12.4324 0C5.57691 0 0 5.57691 0 12.4324C0 19.2879 5.57691 24.8648 12.4324 24.8648C19.2879 24.8648 24.8648 19.2879 24.8648 12.4324C24.8648 5.57691 19.2879 0 12.4324 0ZM17.8716 13.4684L13.4684 13.4684L13.4684 17.8716C13.4684 18.4436 13.0044 18.9076 12.4324 18.9076C11.8605 18.9076 11.3964 18.4436 11.3964 17.8716L11.3964 13.4684H6.99323C6.42128 13.4684 5.95726 13.0044 5.95726 12.4324C5.95726 11.8605 6.42128 11.3964 6.99323 11.3964H11.3964V6.99323C11.3964 6.42128 11.8605 5.95726 12.4324 5.95726C13.0044 5.95726 13.4684 6.42128 13.4684 6.99323V11.3964L17.8716 11.3964C18.4436 11.3964 18.9076 11.8605 18.9076 12.4324C18.9076 13.0044 18.4436 13.4684 17.8716 13.4684Z",
      "fill": "#495BC4"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }