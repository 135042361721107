var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "23",
      "height": "23",
      "viewBox": "0 0 23 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M22.2821 3.84396H19.1559V0.717754C19.1559 0.321362 18.8345 0 18.4381 0C18.0417 0 17.7204 0.321362 17.7204 0.717754V3.84396H14.5942C14.1978 3.84396 13.8765 4.16532 13.8765 4.56171C13.8765 4.9581 14.1978 5.27946 14.5942 5.27946H17.7204V8.40571C17.7204 8.80211 18.0417 9.12347 18.4381 9.12347C18.8345 9.12347 19.1559 8.80211 19.1559 8.40571V5.27946H22.2821C22.6785 5.27946 22.9999 4.9581 22.9999 4.56171C22.9999 4.16532 22.6785 3.84396 22.2821 3.84396Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.40567 13.5576C3.77079 13.5576 0 17.3284 0 21.9633C0 22.3596 0.321362 22.681 0.717754 22.681H16.0937C16.4901 22.681 16.8114 22.3596 16.8114 21.9633C16.8114 17.3284 13.0406 13.5576 8.40567 13.5576Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.40541 3.45801C5.89011 3.45801 3.84375 5.50442 3.84375 8.01977C3.84375 10.5351 5.89011 12.5815 8.40541 12.5815C10.9208 12.5815 12.9672 10.5351 12.9672 8.01977C12.9672 5.50442 10.9208 3.45801 8.40541 3.45801Z",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }