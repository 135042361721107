export const BUSINESS_REQUEST = "BUSINESS_REQUEST";

export const BUSINESS_GET_ALL = "BUSINESS_GET_ALL";
export const BUSINESS_GET_ALL_SUCCESS = "BUSINESS_GET_ALL_SUCCESS";

export const BUSINESS_YEAR_UPDATE = "BUSINESS_YEAR_UPDATE";
export const BUSINESS_YEAR_UPDATE_SUCCESS = "BUSINESS_YEAR_UPDATE_SUCCESS";

export const BUSINESS_YEAR_REGISTER = "BUSINESS_YEAR_REGISTER";
export const BUSINESS_YEAR_REGISTER_SUCCESS = "BUSINESS_YEAR_REGISTER_SUCCESS";

export const BUSINESS_YEARS = "BUSINESS_YEARS";
export const BUSINESS_YEARS_SUCCESS = "BUSINESS_YEARS_SUCCESS";
