var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-overlay', {
    attrs: {
      "z-index": "7",
      "value": _vm.apiProcessing
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c('router-view'), _c('ConfirmDialog', {
    ref: "confirm"
  }), _c('v-snackbar', {
    attrs: {
      "color": "#0c1b73",
      "timeout": "4000"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.snackbar = false;
            }
          }
        }, 'v-btn', attrs, false), [_c('v-icon', [_vm._v(" mdi-close-circle ")])], 1)];
      }
    }]),
    model: {
      value: _vm.snackbar,
      callback: function callback($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" " + _vm._s(_vm.alertText) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }