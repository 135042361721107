var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "8",
      "height": "9",
      "viewBox": "0 0 8 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M4.994 4.56618C4.994 5.81352 4.9895 7.06101 4.99696 8.30821C4.99921 8.68199 4.92257 9.00473 4.48129 8.99812C4.07432 8.99207 3.99163 8.6879 3.99219 8.33127C3.99739 5.78666 3.99739 3.24205 3.99219 0.697445C3.99149 0.340116 4.076 0.036929 4.48297 0.0322884C4.92425 0.0270853 4.99878 0.351085 4.99682 0.724163C4.9895 2.00484 4.994 3.28551 4.994 4.56618Z",
      "fill": "#999999"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6.99061 5.9627C6.99061 5.19799 6.99581 4.43327 6.98836 3.66855C6.98484 3.30377 7.09833 3.02139 7.50319 3.02744C7.87598 3.03306 7.99284 3.29856 7.992 3.64478C7.9875 5.224 7.9875 6.80364 7.99186 8.38314C7.99299 8.72964 7.87486 8.9943 7.50206 8.99908C7.0972 9.00428 6.98484 8.72106 6.9885 8.35699C6.99581 7.55908 6.99061 6.76075 6.99061 5.9627Z",
      "fill": "#999999"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1.99775 7.06776C1.99789 7.51635 1.99592 7.96523 1.9986 8.41382C2.00042 8.76707 1.85038 9.00895 1.47983 8.99966C1.13853 8.99081 0.997346 8.75695 0.998049 8.42648C1.00016 7.47923 0.998893 6.53198 0.998893 5.58473C0.998893 5.25257 1.15063 5.02743 1.49207 5.02349C1.83322 5.01942 1.99508 5.23851 1.99691 5.57221C1.99944 6.07073 1.99761 6.56938 1.99775 7.06776Z",
      "fill": "#999999"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }