<template>
  <v-app class="pa-6">
    <Header />
    <Sidebar />
    <Action />
    <v-main class="content">
      <router-view />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/admin/partials/Header/Header";
import Sidebar from "@/components/admin/partials/Sidebar/Sidebar";
import Footer from "@/components/admin/partials/Footer/Footer";
import Action from "@/components/admin/models/Action";

export default {
  name: "Layout",
  components: {Action, Footer, Header, Sidebar }
};
</script>

<style src="./Layout.scss" lang="scss"></style>
