import axios from "@/services/api";
import "@/store/actions/notification/linkposts";
import {
  GET_ALL_LINK_POSTS,
  LINK_POST_GET,
  GET_ALL_LINK_POSTS_ERROR,
  LINK_POSTS_REQUEST,
  GET_ALL_LINK_POSTS_SUCCESS,
  GET_LINK_POST_SUCCESS,
  ADD_LINK_POST,
  ADD_LINK_POST_SUCCESS,
  ADD_LINK_POST_ERROR,
  EDIT_LINK_POST_SUCCESS,
  EDIT_LINK_POST_ERROR,
  EDIT_LINK_POST,
  DELETE_LINK_POST_SUCCESS,
  DELETE_LINK_POST_ERROR,
  DELETE_LINK_POST,
  GET_ALL_LINK_POST_USER_VIEWS,
  GET_ALL_LINK_POST_USER_VIEWS_SUCCESS,
  GET_ALL_LINK_POST_USER_VIEWS_ERROR
} from "@/store/actions/notification/linkposts";

function initialState() {
  return {
    status: "",
    pagination: {},
    linkposts: [],
    linkpost: {},
    message: "",
    errors: {},
    user_view_list: []
  };
}

const state = initialState();

const getters = {
  allLinkPosts: state => state.linkposts,
  singleLinkPost: state => state.linkpost,
  linkPostPagination: state => state.pagination,
  linkPostViews: state => state.user_view_list
};

const actions = {
  [GET_ALL_LINK_POSTS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/admin/notification/link-posts", {
          params: params
        })
        .then(response => {
          let data = response.data;

          commit("GET_ALL_LINK_POSTS_SUCCESS", {
            linkposts: data.data.notification,
            pagination: data.paginate
          });

          resolve(response);
        })
        .catch(error => {
          commit("GET_ALL_LINK_POSTS_ERROR", { error: error });

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [LINK_POST_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/notification/link-posts/${params.id}`)
        .then(response => {
          let data = response.data;

          commit("GET_LINK_POST_SUCCESS", {
            linkpost: data.data.notification,
            pagination: data.paginate
          });

          resolve(response);
        })
        .catch(error => {
          commit("GET_LINK_POST_ERROR", { error: error });

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [ADD_LINK_POST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/admin/notification/link-posts`, params)
        .then(response => {
          commit("ADD_LINK_POST_SUCCESS", {
            message: response.data.data.message
          });
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });

          resolve(response);
        })
        .catch(error => {
          dispatch("ALERT", {
            show: true,
            text: error.data.error.message
          });
          if (error.status === 422) {
            commit("ADD_LINK_POST_ERROR", {
              message: error.data.error.message,
              errors: error.data.error.errors
            });
            reject(error);
          }

          commit("ADD_LINK_POST_ERROR", {
            message: error.response.data.message
          });
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [EDIT_LINK_POST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/admin/notification/link-posts/${params.id}`, params)
        .then(response => {
          commit("EDIT_LINK_POST_SUCCESS", {
            message: response.data.data.message
          });
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });

          resolve(response);
        })
        .catch(error => {
          dispatch("ALERT", {
            show: true,
            text: error.data.error.message
          });
          if (error.status === 422) {
            commit("EDIT_LINK_POST_ERROR", {
              message: error.data.error.message,
              errors: error.data.error.errors
            });
            reject(error);
          }

          commit("EDIT_LINK_POST_ERROR", {
            message: error.response.data.message
          });
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [DELETE_LINK_POST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(`/admin/notification/link-posts/${params.id}`, params)
        .then(response => {
          commit("DELETE_LINK_POST_SUCCESS", {
            message: response.data.data.message
          });
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });

          resolve(response);
        })
        .catch(error => {
          dispatch("ALERT", {
            show: true,
            text: error.data.error.message
          });
          if (error.status === 422) {
            commit("DELETE_LINK_POST_ERROR", {
              message: error.data.error.message,
              errors: error.data.error.errors
            });
            reject(error);
          }

          commit("DELETE_LINK_POST_ERROR", {
            message: error.response.data.message
          });
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [GET_ALL_LINK_POST_USER_VIEWS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/admin/notification-link-post-clicked-users", {
          params: params
        })
        .then(response => {
          let data = response.data;

          commit("GET_ALL_LINK_POST_USER_VIEWS_SUCCESS", {
            views: data.data.notificationLinkPostClicksUserList
          });

          resolve(response);
        })
        .catch(error => {
          commit("GET_ALL_LINK_POST_USER_VIEWS_ERROR", { error: error });

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [LINK_POSTS_REQUEST]: state => {
    state.status = "loading";
  },
  [GET_ALL_LINK_POSTS_SUCCESS]: (state, params) => {
    state.status = "success";
    state.linkposts = params.linkposts;
    state.pagination = params.pagination;
  },
  [GET_ALL_LINK_POSTS_ERROR]: state => {
    state.status = "error";
  },
  [GET_LINK_POST_SUCCESS]: (state, params) => {
    state.status = "success";
    state.linkpost = params.linkpost;
    state.pagination = params.pagination;
  },

  [ADD_LINK_POST_SUCCESS]: (state, params) => {
    state.status = "success";
    state.message = params.message;
  },
  [ADD_LINK_POST_ERROR]: (state, params) => {
    state.status = "error";
    state.message = params.message;
    state.errors = params.errors;
  },
  [EDIT_LINK_POST_SUCCESS]: (state, params) => {
    state.status = "success";
    state.message = params.message;
  },
  [EDIT_LINK_POST_ERROR]: (state, params) => {
    state.status = "error";
    state.message = params.message;
    state.errors = params.errors;
  },
  [DELETE_LINK_POST_SUCCESS]: (state, params) => {
    state.status = "success";
    state.message = params.message;
  },
  [DELETE_LINK_POST_ERROR]: (state, params) => {
    state.status = "error";
    state.message = params.message;
    state.errors = params.errors;
  },

  [GET_ALL_LINK_POST_USER_VIEWS_SUCCESS]: (state, params) => {
    state.status = "success";
    state.user_view_list = params.views;
  },
  [GET_ALL_LINK_POST_USER_VIEWS_ERROR]: (state, params) => {
    state.status = "error";
    state.message = params.message;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
