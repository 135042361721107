<template>
  <div>
    <svg
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0117 0.999023L5.50526 4.95251L0.998806 0.999023"
        stroke="#525252"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "ArrowDownIcon"
};
</script>

<style lang="scss" scoped></style>
