var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "400",
      "value": _vm.getModalVisibility
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.hideModal($event);
      }
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.getModalTitle) + " ")]), _c('v-card-text', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.getModalText) + " ")]), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    attrs: {
      "color": _vm.getModalAction.cancel.color,
      "text": "",
      "disabled": _vm.getModalLoading
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" " + _vm._s(_vm.getModalAction.cancel.text) + " ")]), _vm.getModalAction["function"] !== null ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": _vm.getModalAction.process.color,
      "loading": _vm.getModalLoading
    },
    on: {
      "click": function click($event) {
        return _vm.executeAction();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.getModalAction.process.text) + " ")]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }