<template>
  <svg
    style="width:15px; height:23px;"
    width="9"
    height="1"
    viewBox="0 0 9 11"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.872005 0.0210791C0.697702 0.0524149 0.472479 0.177755 0.327553 0.320724C0.176751 0.469566 0.057285 0.692831 0.0220327 0.886719C-0.00734423 1.04927 -0.00734423 8.97321 0.0220327 9.13576C0.0964542 9.55095 0.474437 9.92894 0.889631 10.0034C1.05218 10.0327 7.56603 10.0327 7.72858 10.0034C7.92247 9.96811 8.14573 9.84864 8.29457 9.69784C8.44342 9.54899 8.56288 9.32573 8.59618 9.13576C8.61185 9.05546 8.61772 7.95089 8.61772 5.63403C8.61772 2.81189 8.6138 2.23806 8.5903 2.18518C8.55897 2.11076 6.47125 0.0582895 6.39095 0.023037C6.3224 -0.00634003 1.02868 -0.00829792 0.872005 0.0210791ZM5.9738 1.24708C5.9738 1.64073 5.98163 1.95604 5.99534 2.02458C6.03843 2.25764 6.17748 2.43586 6.39487 2.5377L6.51237 2.59254L7.27226 2.59841L8.03018 2.60429L8.02627 5.83967L8.02039 9.07505L7.9773 9.15339C7.92834 9.24543 7.83238 9.33944 7.73641 9.39036C7.66982 9.42561 7.54644 9.42757 4.3091 9.42757C1.07177 9.42757 0.948385 9.42561 0.881797 9.39036C0.785833 9.33944 0.689868 9.24543 0.640907 9.15339L0.59782 9.07505V5.01124C0.59782 1.08452 0.599779 0.945473 0.635031 0.878885C0.705536 0.745709 0.825002 0.643869 0.960136 0.602741C0.987554 0.594908 2.12542 0.587074 3.49243 0.587074L5.9738 0.585115V1.24708ZM7.45244 1.85616L7.60911 2.01479H7.12341C6.66513 2.01479 6.63772 2.01283 6.6005 1.97562C6.56329 1.93841 6.56134 1.91099 6.56134 1.46447V0.992476L6.92953 1.345C7.13125 1.53889 7.36626 1.76999 7.45244 1.85616Z"
      fill="white"
    />
    <path
      d="M2.13462 4.99089C1.88845 5.11317 1.88846 5.47492 2.13464 5.59719C2.15934 5.60984 2.18819 5.61687 2.24376 5.6219C2.29958 5.62695 2.38763 5.63033 2.53411 5.63266C2.82765 5.63732 3.36185 5.6378 4.35652 5.6378C4.48846 5.6378 4.6126 5.63783 4.72942 5.63785C5.65614 5.63804 6.12168 5.63813 6.36371 5.625C6.49778 5.61773 6.57251 5.60626 6.61977 5.58314C6.66192 5.56252 6.68261 5.53055 6.69819 5.50646L2.13462 4.99089ZM2.13462 4.99089C2.15933 4.97823 2.18818 4.9712 2.24376 4.96617C2.29958 4.96112 2.38763 4.95774 2.53411 4.95541C2.82765 4.95075 3.36185 4.95027 4.35652 4.95027C4.48847 4.95027 4.61261 4.95024 4.72942 4.95022C5.65614 4.95003 6.12168 4.94994 6.36371 4.96307C6.49778 4.97034 6.57251 4.98181 6.61977 5.00492C6.66192 5.02555 6.68261 5.05752 6.69819 5.08161C6.70168 5.087 6.70491 5.09199 6.70807 5.09638C6.74665 5.1499 6.76304 5.22464 6.76304 5.29403C6.76304 5.36343 6.74665 5.43817 6.70807 5.49169L2.13462 4.99089Z"
      fill="white"
    />
    <path
      d="M5.9037 6.46251L5.94435 6.49163C5.94127 6.49593 5.93814 6.50061 5.93485 6.50552C5.9184 6.53012 5.89786 6.56082 5.85833 6.58168C5.81384 6.60514 5.74715 6.61685 5.63352 6.62428C5.423 6.63804 5.02003 6.63799 4.21535 6.63787C4.1388 6.63786 4.05862 6.63785 3.97462 6.63785C3.16214 6.63785 2.7233 6.63736 2.4796 6.6327C2.35803 6.63037 2.28336 6.62699 2.23461 6.62188C2.18549 6.61673 2.15851 6.60947 2.13463 6.59723C1.88846 6.47497 1.88846 6.11321 2.13462 5.99094C2.1585 5.9787 2.18548 5.97143 2.23461 5.96628C2.28336 5.96117 2.35803 5.95779 2.4796 5.95546C2.7233 5.9508 3.16214 5.95031 3.97462 5.95031C4.05862 5.95031 4.1388 5.9503 4.21535 5.95029C5.02003 5.95018 5.423 5.95012 5.63352 5.96388C5.74715 5.97131 5.81384 5.98302 5.85833 6.00649C5.89786 6.02734 5.9184 6.05804 5.93485 6.08264C5.93814 6.08756 5.94127 6.09223 5.94435 6.09653L5.9037 6.12565M5.9037 6.46251L5.94426 6.49175C5.98285 6.43823 5.99924 6.36348 5.99924 6.29408C5.99924 6.22468 5.98285 6.14994 5.94426 6.09641L5.9037 6.12565M5.9037 6.46251C5.89 6.48164 5.88046 6.49785 5.86816 6.51159C5.79607 6.59214 5.6292 6.58785 3.97462 6.58785C2.3491 6.58785 2.2218 6.58589 2.15717 6.5526V6.03556C2.2218 6.00227 2.3491 6.00031 3.97462 6.00031C5.6292 6.00031 5.79607 5.99603 5.86816 6.07657C5.88046 6.09031 5.89 6.10653 5.9037 6.12565M5.9037 6.46251C5.96442 6.3783 5.96442 6.20987 5.9037 6.12565"
      fill="white"
    />
    <rect x="2" y="7" width="5" height="0.8" rx="0.4" fill="white" />
  </svg>
</template>

<script>
export default {
  name: "WhiteDailyReportIcon"
};
</script>

<style lang="scss" scoped></style>
