var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "16",
      "height": "13",
      "viewBox": "0 0 16 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M4.48048 10.5055H1.88672V4.70568C1.88672 4.56134 2.00372 4.44434 2.14806 4.44434H4.21914C4.36348 4.44434 4.48048 4.56134 4.48048 4.70568V10.5055Z",
      "fill": _vm.fill
    }
  }), _c('path', {
    attrs: {
      "d": "M9.29884 10.5072H6.70508V3.56408C6.70508 3.41974 6.82208 3.30273 6.96642 3.30273H9.0375C9.18184 3.30273 9.29884 3.41974 9.29884 3.56408V10.5072Z",
      "fill": _vm.fill
    }
  }), _c('path', {
    attrs: {
      "d": "M14.1192 10.5088H11.5254V5.1256C11.5254 4.98126 11.6424 4.86426 11.7867 4.86426H13.8578C14.0021 4.86426 14.1192 4.98126 14.1192 5.1256V10.5088Z",
      "fill": _vm.fill
    }
  }), _c('path', {
    attrs: {
      "d": "M15.5302 12.3692H0.469759C0.210327 12.3692 0 12.1589 0 11.8994C0 11.64 0.210327 11.4297 0.469759 11.4297H15.5302C15.7897 11.4297 16 11.64 16 11.8994C16 12.1589 15.7897 12.3692 15.5302 12.3692Z",
      "fill": _vm.fill
    }
  }), _c('path', {
    attrs: {
      "d": "M15.805 1.78754C15.8206 1.59121 15.7062 1.35013 15.4441 1.28916L13.9263 0.920239C13.6741 0.858763 13.4202 1.01363 13.3589 1.26573C13.2977 1.51784 13.4523 1.77188 13.7044 1.83314L14.1337 1.93749L12.9168 2.68844L8.30264 0.0615502C8.16998 -0.0140184 8.00876 -0.0203758 7.87065 0.0445449L3.2841 2.19736L1.09158 0.82898C0.871515 0.691529 0.581736 0.758673 0.444347 0.978771C0.306989 1.19884 0.374071 1.48862 0.594169 1.62601L3.0036 3.1297C3.13879 3.2141 3.30759 3.22419 3.4519 3.15645L8.04944 0.998469L12.6941 3.64274C12.815 3.70613 12.9834 3.73898 13.1732 3.63426L14.6484 2.72389L14.5502 3.18401C14.4421 3.79604 15.3085 3.96985 15.469 3.38024L15.7946 1.85528C15.7983 1.83389 15.7997 1.8369 15.805 1.78754V1.78754Z",
      "fill": _vm.fill
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }