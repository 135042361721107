var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "width": "17px",
      "height": "17px"
    },
    attrs: {
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M16.8387 16.0751L12.4496 11.7561C13.5989 10.5074 14.3052 8.85588 14.3052 7.03858C14.3046 3.15103 11.1025 0 7.1523 0C3.20206 0 0 3.15103 0 7.03858C0 10.9261 3.20206 14.0772 7.1523 14.0772C8.85908 14.0772 10.4245 13.4868 11.6541 12.5054L16.0603 16.8414C16.275 17.0529 16.6235 17.0529 16.8382 16.8414C17.0534 16.6299 17.0534 16.2866 16.8387 16.0751ZM7.1523 12.9942C3.80997 12.9942 1.10048 10.3278 1.10048 7.03858C1.10048 3.74936 3.80997 1.08293 7.1523 1.08293C10.4947 1.08293 13.2041 3.74936 13.2041 7.03858C13.2041 10.3278 10.4947 12.9942 7.1523 12.9942Z",
      "fill": "#D7D6D6"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }