import axios from "axios";
import store from "@/store/index.js";

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    const token = store.getters.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    config.baseURL = process.env.VUE_APP_BASEURL;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  function(error) {
    // Do something with response error
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      store.commit("AUTH_LOGOUT");
    } else if (error.response.status === 422) {
      if (error?.response?.data?.message && !store.state.alert.disabled) {
        store.dispatch("ALERT", {
          show: true,
          text: error?.response?.data?.message
        });
      }
    }
    return Promise.reject(error.response);
  }
);

export default axios;
