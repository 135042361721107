import axios from "@/services/api";
import {
  CALCULATION_TEST_GET,
  CALCULATION_TEST_GET_SUCCESS,
  CALCULATION_TEST_REQUEST
} from "@/store/actions/calculation_test";

function initialState() {
  return {
    testCalc: {}
  };
}

const state = initialState();

const getters = {
  getTestCalc: state => state.testCalc
};

const actions = {
  [CALCULATION_TEST_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/calculation-test`, {
          params: params
        })
        .then(response => {
          commit("CALCULATION_TEST_GET_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [CALCULATION_TEST_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.testCalc = params.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
