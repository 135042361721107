import BellIcon from "@/components/icons/BellIcon";
import GearIcon from "@/components/icons/GearIcon";
import BusinessIcon from "@/components/icons/BusinessIcon";
import CustomChevronRight from "@/components/icons/CustomChevronRight";
import InfoIcon from "@/components/icons/InfoIcon";
import PerformanceIcon from "@/components/icons/PerformanceIcon";
import SalesAndCostIcon from "@/components/icons/SalesAndCostIcon";
import BankIcon from "@/components/icons/BankIcon";
import GoBackIcon from "@/components/icons/GoBackIcon";
import EditIcon from "@/components/icons/EditIcon";
import UsersIcon from "@/components/icons/UsersIcon";
import UserIcon from "@/components/icons/UserIcon";
import AdminUsersIcon from "@/components/icons/AdminUsersIcon";
import CustomChevronUp from "@/components/icons/CustomChevronUp";
import CustomChevronDown from "@/components/icons/CustomChevronDown";
import CheckIcon from "@/components/icons/CheckIcon";
import CrossIcon from "@/components/icons/CrossIcon";
import AddUserIcon from "@/components/icons/AddUserIcon";
import AddAdminUserIcon from "@/components/icons/AddAdminUserIcon";
import CheckboxOnIcon from "@/components/icons/CheckboxOnIcon";
import CheckboxOffIcon from "@/components/icons/CheckboxOffIcon";
import TripleDotsIcon from "@/components/icons/TripleDotsIcon";
import AccountingIcon from "@/components/icons/AccountingIcon";
import ClearIcon from "@/components/icons/ClearIcon";
import RightArrowIcon from "@/components/icons/RightArrowIcon";
import HomeIcon from "@/components/icons/HomeIcon";
import MonthlyReportIcon from "@/components/icons/MonthlyReportIcon";
import DailyReportIcon from "@/components/icons/DailyReportIcon";
import CashSalesIcon from "@/components/icons/CashSalesIcon";
import AccountsPayableIcon from "@/components/icons/AccountsPayableIcon";
import HorizontalKebabIcon from "@/components/icons/HorizontalKebabIcon";
import WhiteMonthlyReportIcon from "@/components/icons/WhiteMonthlyReportIcon";
import WhiteDailyReportIcon from "@/components/icons/WhiteDailyReportIcon";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon";
import PurpleDownArrowIcon from "@/components/icons/PurpleDownArrowIcon";
import ArrowDownIcon from "@/components/icons/ArrowDownIcon";
import ArrowUpIcon from "@/components/icons/ArrowUpIcon";
import ArrowLeftPurpleIcon from "@/components/icons/ArrowLeftPurpleIcon";
import ArrowRightPurpleIcon from "@/components/icons/ArrowRightPurpleIcon";
import StoreIcon from "@/components/icons/StoreIcon";
import ShopIcon from "@/components/icons/ShopIcon";
import SearchIcon from "@/components/icons/SearchIcon";
import PersonIcon from "@/components/icons/PersonIcon";
import StatIcon from "@/components/icons/StatIcon";
import SaveIcon from "@/components/icons/SaveIcon";
import CashTellerIcon from "@/components/icons/CashTellerIcon";
import AccountPayableIcon from "@/components/icons/AccountPayableIcon";
import MoreIcon from "@/components/icons/MoreIcon";
import AccountIcon from "@/components/icons/AccountIcon";
import SaleIcon from "@/components/icons/SaleIcon";
import CostIcon from "@/components/icons/CostIcon";
import BusinessSettingIcon from "@/components/icons/BusinessSettingIcon";
import InventoryIcon from "@/components/icons/InventoryIcon";
import MailingIcon from "@/components/icons/MailingIcon";
import CloudyWeatherIcon from "@/components/icons/CloudyWeatherIcon";
import SunnyWeatherIcon from "@/components/icons/SunnyWeatherIcon";
import RainyWeatherIcon from "@/components/icons/RainyWeatherIcon";
import PurpleSunnyIcon from "@/components/icons/PurpleSunnyIcon";
import PurpleCloudyIcon from "@/components/icons/PurpleCloudyIcon";
import DropdownIcon from "@/components/icons/DropdownIcon";
import RefreshIcon from "@/components/icons/RefreshIcon";
import PlusIcon from "@/components/icons/PlusIcon";
import SalesPersonIcon from "@/components/icons/SalesPersonIcon";
import CloseIcon from "@/components/icons/CloseIcon";
import AddMailUser from "@/components/icons/AddMailUser";
export default {
  bell: {
    component: BellIcon
  },
  gear: {
    component: GearIcon
  },
  business: {
    component: BusinessIcon
  },
  info: {
    component: InfoIcon
  },
  accounting: {
    component: AccountingIcon
  },
  performance: {
    component: PerformanceIcon
  },
  sales: {
    component: SalesAndCostIcon
  },
  bank: {
    component: BankIcon
  },
  goBack: {
    component: GoBackIcon
  },
  edit: {
    component: EditIcon
  },
  user: {
    component: UserIcon
  },
  users: {
    component: UsersIcon
  },
  adminUsers: {
    component: AdminUsersIcon
  },
  addUser: {
    component: AddUserIcon
  },
  addAdminUser: {
    component: AddAdminUserIcon
  },
  check: {
    component: CheckIcon
  },
  customCheckboxOn: {
    component: CheckboxOnIcon
  },
  customCheckboxOff: {
    component: CheckboxOffIcon
  },
  cross: {
    component: CrossIcon
  },
  customChevronRight: {
    component: CustomChevronRight
  },
  customChevronUp: {
    component: CustomChevronUp
  },
  customChevronDown: {
    component: CustomChevronDown
  },
  tripleDots: {
    component: TripleDotsIcon
  },
  clear: {
    component: ClearIcon
  },
  rightArrow: {
    component: RightArrowIcon
  },
  home: {
    component: HomeIcon
  },
  monthlyReport: {
    component: MonthlyReportIcon
  },
  dailyReport: {
    component: DailyReportIcon
  },
  cashSales: {
    component: CashSalesIcon
  },
  accountsPayable: {
    component: AccountsPayableIcon
  },
  accountsPayableWhite: {
    component: AccountsPayableIcon,
    props: {}
  },
  horizontalKebab: {
    component: HorizontalKebabIcon
  },
  whiteMonthlyReport: {
    component: WhiteMonthlyReportIcon
  },
  whiteDailyReport: {
    component: WhiteDailyReportIcon
  },
  arrowRight: {
    component: ArrowRightIcon
  },
  purpleDownArrow: {
    component: PurpleDownArrowIcon
  },
  arrowDown: {
    component: ArrowDownIcon
  },
  arrowUp: {
    component: ArrowUpIcon
  },
  purpleArrowLeft: {
    component: ArrowLeftPurpleIcon
  },
  purpleArrowRight: {
    component: ArrowRightPurpleIcon
  },
  store: {
    component: StoreIcon
  },
  shop: {
    component: ShopIcon
  },
  search: {
    component: SearchIcon
  },
  person: {
    component: PersonIcon
  },
  stat: {
    component: StatIcon
  },
  save: {
    component: SaveIcon
  },
  cashTellerWhite: {
    component: CashTellerIcon,
    props: { fill: "white" }
  },
  accountPayableWhite: {
    component: AccountPayableIcon,
    props: { fill: "white" }
  },
  more: {
    component: MoreIcon
  },
  account: {
    component: AccountIcon
  },
  sale: {
    component: SaleIcon
  },
  cost: {
    component: CostIcon
  },
  costWhite: {
    component: CostIcon,
    props: { fill: "white" }
  },
  businessSetting: {
    component: BusinessSettingIcon
  },
  businessSettingWhite: {
    component: BusinessSettingIcon,
    props: { fill: "white" }
  },
  inventory: {
    component: InventoryIcon
  },
  inventoryWhite: {
    component: InventoryIcon,
    props: { fill: "white", stroke: "white", strokeWidth: "0.2" }
  },
  mailing: {
    component: MailingIcon
  },
  mailingWhite: {
    component: MailingIcon,
    props: { fill: "white" }
  },
  cloudyWeather: {
    component: CloudyWeatherIcon
  },
  sunnyWeather: {
    component: SunnyWeatherIcon
  },
  rainyWeather: {
    component: RainyWeatherIcon
  },
  purpleRainyWeather: {
    component: RainyWeatherIcon,
    props: { fill: "#7367F0" }
  },
  purpleSunny: {
    component: PurpleSunnyIcon
  },
  purpleCloudy: {
    component: PurpleCloudyIcon
  },
  dropdownCustom: {
    component: DropdownIcon
  },
  refresh: {
    component: RefreshIcon
  },
  saleWhite: {
    component: SaleIcon,
    props: { fill: "white" }
  },
  plus: {
    component: PlusIcon
  },
  salesPerson: {
    component: SalesPersonIcon
  },
  closeIcon: {
    component: CloseIcon
  },
  addMailUser: {
    component: AddMailUser
  }
};
