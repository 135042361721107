import axios from "@/services/api";
import {
  SHOP_REQUEST,
  SHOP_GET_ALL,
  SHOP_GET_ALL_SUCCESS,
  SHOP_REGISTER,
  SHOP_REGISTER_SUCCESS,
  SHOP_DELETE,
  SHOP_DELETE_SUCCESS,
  SHOP_GET,
  SHOP_GET_SUCCESS,
  SHOP_UPDATE,
  SHOP_UPDATE_SUCCESS,
  SHOP_INVITE_USER,
  SHOP_INVITE_USER_SUCCESS,
  SHOP_USER_GET,
  SHOP_USER_GET_SUCCESS,
  SHOP_USER_DELETE,
  SHOP_USER_DELETE_SUCCESS,
  SHOP_USER_UPDATE,
  SHOP_USER_UPDATE_SUCCESS,
  SHOP_MONTHLY_REPORT_ALL,
  SHOP_MONTHLY_REPORT_ALL_SUCCESS,
  SHOP_DAILY_REPORT_ALL,
  SHOP_DAILY_REPORT_ALL_SUCCESS,
  SHOP_MONTHLY_REPORT,
  SHOP_MONTHLY_REPORT_SUCCESS,
  SHOP_DAILY_REPORT,
  SHOP_DAILY_REPORT_SUCCESS
} from "@/store/actions/shop";

function initialState() {
  return {
    shops: [],
    shop: {},
    userShop: {},
    dailyReports: [],
    dailyReport: {},
    monthlyCumulatedSalesReport: {},
    monthlyCumulatedCostsReport: {},
    monthlyReports: [],
    monthlyReport: {},
    yearlyCumulatedSalesReport: {},
    yearlyCumulatedCostsReport: {},
    ShopPagination: {}
  };
}

const state = initialState();

const getters = {
  getShops: state => state.shops,
  getShop: state => state.shop,
  getUserShop: state => state.userShop,
  getShopDailyReports: state => state.dailyReports,
  getShopDailyReport: state => state.dailyReport,
  getShopMonthlyCumulatedSalesReport: state =>
    state.monthlyCumulatedSalesReport,
  getShopMonthlyCumulatedCostsReport: state =>
    state.monthlyCumulatedCostsReport,
  getShopMonthlyReports: state => state.monthlyReports,
  getShopMonthlyReport: state => state.monthlyReport,
  getShopYearlyCumulatedSalesReport: state =>
    state.yearlyCumulatedSalesReport,
  getShopYearlyCumulatedCostsReport: state =>
    state.yearlyCumulatedCostsReport,
  ShopPagination: state => state.ShopPagination
};

const actions = {
  [SHOP_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops`, { params: params })
        .then(response => {
          commit("SHOP_GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${params.id}`)
        .then(response => {
          commit("SHOP_GET_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(`/admin/shops/${params.id}`)
        .then(response => {
          commit("SHOP_DELETE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_REGISTER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/admin/shops`, params)
        .then(response => {
          commit("SHOP_REGISTER_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });

      axios
        .put(`/admin/shops/${params.shop_id}`, params)
        .then(response => {
          commit("SHOP_UPDATE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_INVITE_USER]: ({ commit, dispatch }, params) => {
    dispatch("DISABLE_ALERT", {}, { root: true });
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/admin/user-shops/invitation`, params)
        .then(response => {
          commit("SHOP_INVITE_USER_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
          dispatch("ENABLE_ALERT", {}, { root: true });
        });
    });
  },
  [SHOP_USER_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${params.shop_id}/user-shops/${params.user_id}`)
        .then(response => {
          commit("SHOP_USER_GET_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_USER_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });

      axios
        .put(`/admin/shops/${params.shop_id}/user-shops/${params.id}`, params)
        .then(response => {
          commit("SHOP_USER_UPDATE_SUCCESS", response.data);
          resolve(response);
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_USER_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(`/admin/shops/${params.shop_id}/user-shops/${params.user_id}`)
        .then(response => {
          commit("SHOP_USER_DELETE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_DAILY_REPORT_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${params.shop_id}/daily-reports`, {
          params: params.params
        })
        .then(response => {
          commit("SHOP_DAILY_REPORT_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_DAILY_REPORT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${params.shop_id}/daily-reports/${params.date}`)
        .then(response => {
          commit("SHOP_DAILY_REPORT_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_MONTHLY_REPORT_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${params.shop_id}/monthly-reports`, {
          params: params.params
        })
        .then(response => {
          commit("SHOP_MONTHLY_REPORT_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_MONTHLY_REPORT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${params.shop_id}/monthly-reports/${params.month}`)
        .then(response => {
          commit("SHOP_MONTHLY_REPORT_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [SHOP_REQUEST]: state => {
    state.status = "loading";
  },

  [SHOP_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.shops = params.data.shops;
    state.ShopPagination = params.paginate;
  },

  [SHOP_REGISTER_SUCCESS]: state => {
    state.status = "success";
  },

  [SHOP_UPDATE_SUCCESS]: state => {
    state.status = "success";
  },

  [SHOP_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.shop = params.data.shop;
  },

  [SHOP_DELETE_SUCCESS]: state => {
    state.status = "success";
  },

  [SHOP_USER_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.userShop = params.data.user_shop;
  },

  [SHOP_USER_UPDATE_SUCCESS]: state => {
    state.status = "success";
  },

  [SHOP_USER_DELETE_SUCCESS]: state => {
    state.status = "success";
  },

  [SHOP_DAILY_REPORT_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.dailyReports = params.data.daily_reports;
    state.monthlyCumulatedSalesReport =
      params.data.monthly_cumulated_sales_report;
    state.monthlyCumulatedCostsReport =
      params.data.monthly_cumulated_costs_report;
  },

  [SHOP_DAILY_REPORT_SUCCESS]: (state, params) => {
    state.dailyReport = params.data.daily_report;
  },

  [SHOP_MONTHLY_REPORT_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.monthlyReports = params.data.monthly_reports;
    state.yearlyCumulatedSalesReport =
      params.data.yearly_cumulated_sales_report;
    state.yearlyCumulatedCostsReport =
      params.data.yearly_cumulated_costs_report;
  },

  [SHOP_MONTHLY_REPORT_SUCCESS]: (state, params) => {
    state.monthlyReport = params.data.monthly_report;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
