import axios from "@/services/api";
import {
  STATS_REQUEST,
  STATS_ERROR,
  STATS_SUCCESS,
  USER_STATS_GET,
  USER_STATS_GET_SUCCESS,
  USER_STATS_GET_ERROR
} from "@/store/actions/stats";

function initialState() {
  return {
    status: "",
    users: {},
    notifications: {},
    user_stats: {}
  };
}

const state = initialState();

const getters = {
  totalUsers: state => state.users.total,
  totalDraftCount: state => state.notifications.draft,
  getUserStats: state => state.user_stats
};

const actions = {
  [STATS_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/stats`)
        .then(response => {
          commit("STATS_SUCCESS", { ...response.data.data });

          resolve(response);
        })
        .catch(error => {
          commit("STATS_ERROR");

          reject(error);
        })
        .finally(() => dispatch("API_PROCESSING", false, { root: true }));
    });
  },
  [USER_STATS_GET]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/users/stats`)
        .then(response => {
          commit("USER_STATS_GET_SUCCESS", { ...response.data.data });

          resolve(response);
        })
        .catch(error => {
          commit("USER_STATS_GET_ERROR");

          reject(error);
        })
        .finally(() => dispatch("API_PROCESSING", false, { root: true }));
    });
  }
};

const mutations = {
  [STATS_REQUEST]: state => {
    state.status = "loading";
  },
  [STATS_SUCCESS]: (state, params) => {
    state.status = "success";
    state.users = params.users;
    state.notifications = params.notifications;
  },
  [STATS_ERROR]: state => {
    state.status = "error";
  },
  [USER_STATS_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.user_stats = params.stats;
  },
  [USER_STATS_GET_ERROR]: state => {
    state.status = "error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
