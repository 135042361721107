<template>
  <svg
    style="width 12px; height:12px"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line y1="5.5" x2="12" y2="5.5" stroke="#7367F0" />
    <line x1="6.5" y1="2.18557e-08" x2="6.5" y2="12" stroke="#7367F0" />
  </svg>
</template>

<script>
export default {
  name: "PlusIcon"
};
</script>

<style lang="scss" scoped></style>
