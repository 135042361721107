import axios from "@/services/api";
import "@/store/actions/notification/tags";
import {
  GET_ALL_TAGS,
  TAG_GET,
  GET_ALL_TAGS_ERROR,
  TAGS_REQUEST,
  GET_ALL_TAGS_SUCCESS,
  GET_TAG_SUCCESS,
  ADD_TAG,
  ADD_TAG_SUCCESS,
  ADD_TAG_ERROR,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_ERROR,
  EDIT_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_ERROR,
  DELETE_TAG
} from "@/store/actions/notification/tags";

function initialState() {
  return {
    status: "",
    pagination: {},
    tags: [],
    tag: {},
    message: "",
    errors: {}
  };
}

const state = initialState();

const getters = {
  allTags: state => state.tags,
  singleTag: state => state.tag,
  tagPagination: state => state.pagination
};

const actions = {
  [GET_ALL_TAGS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/admin/notification/tags", {
          params: params
        })
        .then(response => {
          let data = response.data;

          commit("GET_ALL_TAGS_SUCCESS", {
            tags: data.data.tags,
            pagination: data.paginate
          });

          resolve(response);
        })
        .catch(error => {
          commit("GET_ALL_TAGS_ERROR", { error: error });

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [TAG_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/notification/tags/${params.id}`)
        .then(response => {
          let data = response.data;

          commit("GET_TAG_SUCCESS", {
            tag: data.data.tag
          });

          resolve(response);
        })
        .catch(error => {
          commit("GET_TAG_ERROR", { error: error });

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [ADD_TAG]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/admin/notification/tags`, params)
        .then(response => {
          commit("ADD_TAG_SUCCESS", {
            message: response.data.data.message
          });
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });

          resolve(response);
        })
        .catch(error => {
          dispatch("ALERT", {
            show: true,
            text: error.data.error.message
          });
          if (error.status === 422) {
            commit("ADD_TAG_ERROR", {
              message: error.data.error.message,
              errors: error.data.error.errors
            });
            reject(error);
          }

          if (error.response) {
            commit("ADD_TAG_ERROR", {
              message: error.response.data.message
            });
          }
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [EDIT_TAG]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/admin/notification/tags/${params.id}`, params)
        .then(response => {
          commit("EDIT_TAG_SUCCESS", {
            message: response.data.data.message
          });
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });

          resolve(response);
        })
        .catch(error => {
          dispatch("ALERT", {
            show: true,
            text: error.data.error.message
          });
          if (error.status === 422) {
            commit("EDIT_TAG_ERROR", {
              message: error.data.error.message,
              errors: error.data.error.errors
            });
            reject(error);
          }

          commit("EDIT_TAG_ERROR", {
            message: error.response.data.message
          });
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [DELETE_TAG]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(`/admin/notification/tags/${params.id}`, params)
        .then(response => {
          commit("DELETE_TAG_SUCCESS", {
            message: response.data.data.message
          });
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });

          resolve(response);
        })
        .catch(error => {
          dispatch("ALERT", {
            show: true,
            text: error.data.error.message
          });
          if (error.status === 422) {
            commit("DELETE_TAG_ERROR", {
              message: error.data.error.message,
              errors: error.data.error.errors
            });
            reject(error);
          }

          commit("DELETE_TAG_ERROR", {
            message: error.response.data.message
          });
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [TAGS_REQUEST]: state => {
    state.status = "loading";
  },
  [GET_ALL_TAGS_SUCCESS]: (state, params) => {
    state.status = "success";
    state.tags = params.tags;
    state.pagination = params.pagination;
  },
  [GET_ALL_TAGS_ERROR]: state => {
    state.status = "error";
  },
  [GET_TAG_SUCCESS]: (state, params) => {
    state.status = "success";
    state.tag = params.tag;
  },
  [ADD_TAG_SUCCESS]: (state, params) => {
    state.status = "success";
    state.message = params.message;
  },
  [ADD_TAG_ERROR]: (state, params) => {
    state.status = "error";
    state.message = params.message;
    state.errors = params.errors;
  },
  [EDIT_TAG_SUCCESS]: (state, params) => {
    state.status = "success";
    state.message = params.message;
  },
  [EDIT_TAG_ERROR]: (state, params) => {
    state.status = "error";
    state.message = params.message;
    state.errors = params.errors;
  },

  [DELETE_TAG_SUCCESS]: (state, params) => {
    state.status = "success";
    state.message = params.message;
  },
  [DELETE_TAG_ERROR]: (state, params) => {
    state.status = "error";
    state.message = params.message;
    state.errors = params.errors;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
