import axios from "@/services/api";
import {
  BUSINESS_REQUEST,
  BUSINESS_GET_ALL,
  BUSINESS_GET_ALL_SUCCESS,
  BUSINESS_YEAR_UPDATE,
  BUSINESS_YEAR_UPDATE_SUCCESS,
  BUSINESS_YEAR_REGISTER,
  BUSINESS_YEAR_REGISTER_SUCCESS,
  BUSINESS_YEARS,
  BUSINESS_YEARS_SUCCESS
} from "@/store/actions/business";

function initialState() {
  return {
    buisnesses: [],
    businesses_years: []
  };
}

const state = initialState();

const getters = {
  getBuisnesses: state => state.buisnesses,
  getBusinessesYears: state => state.businesses_years
};

const actions = {
  [BUSINESS_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("BUSINESS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${params.id}/business-days`, { params })
        .then(response => {
          commit("BUSINESS_GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [BUSINESS_YEAR_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("BUSINESS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(
          `/admin/shops/${params.shop_id}/business-days/${params.business_id}`,
          params.data
        )
        .then(response => {
          commit("BUSINESS_YEAR_UPDATE_SUCCESS", response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [BUSINESS_YEAR_REGISTER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("BUSINESS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/admin/shops/${params.shop_id}/business-days`, params.data)
        .then(response => {
          commit("BUSINESS_YEAR_REGISTER_SUCCESS", response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [BUSINESS_YEARS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("BUSINESS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${params.id}/business-years`)
        .then(response => {
          commit("BUSINESS_YEARS_SUCCESS", response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [BUSINESS_REQUEST]: state => {
    state.status = "loading";
  },

  [BUSINESS_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.businesses = params.data.business_day;
  },

  [BUSINESS_YEAR_UPDATE_SUCCESS]: (state, params) => {
    state.status = "success";
  },

  [BUSINESS_YEAR_REGISTER_SUCCESS]: (state, params) => {
    state.status = "success";
  },

  [BUSINESS_YEARS_SUCCESS]: (state, params) => {
    state.status = "success";
    state.businesses_years = params.data.data.business_years;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
