import i18n from "@/plugins/i18n";
import {
  required,
  email,
  numeric,
  max_value,
  min_value,
  max,
  min,
  size,
  confirmed
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("max", {
  ...max,
  message: i18n.t("field_max_message")
});

extend("min", {
  ...min,
  message: i18n.t("field_min_message")
});

extend("max_value", {
  ...max_value,
  message: i18n.t("field_max_value_message")
});

extend("min_value", {
  ...min_value,
  message: i18n.t("field_min_value_message")
});

// extend("required", {
//   ...required,
//   message: i18n.t("field_required_message")
// });

extend("required", {
  ...required,
  message(value, placeholders) {
    if (placeholders.args) {
      return placeholders.args + i18n.t("field_required_message");
    } else {
      return i18n.t("field_required_message");
    }
  },
  // message:name => name+{params}+i18n.t("field_required_message"),
  params: ["args"]
});

extend("email", {
  ...email,
  message: i18n.t("field_email_message")
});

extend("numeric", {
  ...numeric,
  message: i18n.t("field_numeric_message")
});

extend("tel", {
  computesRequired: true,
  validate: value =>
    /^(\d+-?)+\d+$/.test(value) && (value.length === 12 || value.length === 13),
  message: i18n.t("field_phone_message")
});

extend("password", {
  computesRequired: true,
  validate: value =>
    /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/.test(
      value
    ),
  message: i18n.t("field_invalid_password_rules_message")
});

extend("size", {
  ...size,
  message: i18n.t("field_size_message")
});

extend("confirmed", {
  ...confirmed,
  message: i18n.t("field_password_confirmed_message")
});

extend("url", {
  message: i18n.t("field_must_be_url_message"),
  validate: value => {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      );
    }

    return false;
  }
});

extend("enter_half_width_numbers_hyphens", {
  message: i18n.t("field_enter_half_width_numbers_hyphens"),
  validate: value => {
    // validation allowing only english language

    let regexElevenPhoneHyphen = /^\(?\d{3}\)?[- ]?(\d{4})[- ]?(\d{4})$|^\(?\d{3}\)?[- ]?(\d{3})[- ]?(\d{4})$|^\(?\d{2}\)?[- ]?(\d{4})[- ]?(\d{4})$/;
    let regexTenPhoneHyphen = /^(1-)?\d{3}-\d{3}-\d{4}$/;
    return regexElevenPhoneHyphen.test(value)
      ? true
      : regexTenPhoneHyphen.test(value); // Validate 10 or 11 digit phone number hyphens
  }
});
