import axios from "@/services/api";
import {
  CASHBOOK_REQUEST,
  CASHBOOK_GET_ALL,
  CASHBOOK_GET_ALL_SUCCESS,
  CASHBOOK_GET,
  CASHBOOK_GET_SUCCESS,
  CASHBOOK_REGISTER,
  CASHBOOK_REGISTER_SUCCESS,
  CASHBOOK_UPDATE,
  CASHBOOK_UPDATE_SUCCESS,
  CASHBOOK_DELETE,
  CASHBOOK_DELETE_SUCCESS
} from "@/store/actions/cashbook";

function initialState() {
  return {
    cashbooks: [],
    cashbook: {},
    cashbookBalance: {},
    pagination: {}
  };
}

const state = initialState();

const getters = {
  getCashbooks: state => state.cashbooks,
  getCashbook: state => state.cashbook,
  getCashbookBalance: state => state.cashbookBalance,
  getCashbookPagination: state => state.pagination
};

const actions = {
  [CASHBOOK_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("CASHBOOK_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${params.id}/cash-flows`, {
          params: { ...params.param }
        })
        .then(response => {
          commit("CASHBOOK_GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [CASHBOOK_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("CASHBOOK_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/admin/shops/${params.id}/cash-flows/${params.cash_flow_id}`, {
          params: { ...params.param }
        })
        .then(response => {
          commit("CASHBOOK_GET_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [CASHBOOK_REGISTER]: ({ commit, dispatch }, { params, shop_id }) => {
    return new Promise((resolve, reject) => {
      commit("CASHBOOK_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/admin/shops/${shop_id}/cash-flows`, params)
        .then(response => {
          commit("CASHBOOK_REGISTER_SUCCESS", response.data);
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [CASHBOOK_UPDATE]: (
    { commit, dispatch },
    { params, shop_id, cash_flow_id }
  ) => {
    return new Promise((resolve, reject) => {
      commit("CASHBOOK_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/admin/shops/${shop_id}/cash-flows/${cash_flow_id}`, params)
        .then(response => {
          commit("CASHBOOK_UPDATE_SUCCESS", response.data);
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [CASHBOOK_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("CASHBOOK_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(
          `/admin/shops/${params.shop_id}/cash-flows/${params.cash_flow_id}`,
          {
            params: { ...params.param }
          }
        )
        .then(response => {
          commit("CASHBOOK_DELETE_SUCCESS", response.data);
          dispatch("ALERT", {
            show: true,
            text: response.data.data.message
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [CASHBOOK_REQUEST]: state => {
    state.status = "loading";
  },

  [CASHBOOK_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.cashbooks = params.data.cash_flow;
    state.cashbookBalance = params.data?.balance?.cumulative_balance || 0;
    if (params.paginate)
      state.pagination = params.paginate
  },

  [CASHBOOK_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.cashbook = params.data.cash_flow;
  },

  [CASHBOOK_REGISTER_SUCCESS]: (state, params) => {
    state.status = "success";
  },

  [CASHBOOK_UPDATE_SUCCESS]: (state, params) => {
    state.status = "success";
  },

  [CASHBOOK_DELETE_SUCCESS]: (state, params) => {
    state.status = "success";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
