<template>
  <v-app>
    <v-overlay z-index="7" :value="apiProcessing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <router-view />
    <ConfirmDialog ref="confirm"></ConfirmDialog>
    <v-snackbar v-model="snackbar" color="#0c1b73" timeout="4000">
      {{ alertText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>
            mdi-close-circle
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
<script>
import ConfirmDialog from "@/components/admin/models/AppConfirmDialog";
export default {
  components: { ConfirmDialog },
  data() {
    return {
      apiProcessing: false,
      snackbar: false,
      alertText: null
    };
  },

  created() {
    this.apiProcessing = this.$store.state.apiProcessing;
  },
  mounted() {
    this.$root.confirm = this.$refs.confirm;
  },
  watch: {
    "$store.state.apiProcessing": function() {
      this.apiProcessing = this.$store.state.apiProcessing;
    },
    "$store.state.alert": function() {
      this.snackbar = this.$store.state.alert.show;
      this.alertText = this.$store.state.alert.text;
    }
  }
};
</script>
