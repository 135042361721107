<template>
  <svg
    style="width:12px;height:7px"
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4824 1.5293L5.97597 5.48279L1.46951 1.5293"
      stroke="#7367F0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "PurpleDownArrowIcon"
};
</script>

<style lang="scss" scoped></style>
