var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "x": "0.5",
      "y": "0.5",
      "width": "17",
      "height": "17",
      "rx": "3.5",
      "fill": "#F7F7F7",
      "stroke": "#C4C4C4"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }