import axios from "@/services/api";
import {
  FETCH_SHOP_FIXED_COST,
  FETCH_SHOP_FIXED_COST_SUCCESS,
  UPDATE_SHOP_FIXED_COST,
  CREATE_SHOP_FIXED_COST
} from "@/store/actions/fixedcost";

function initialState() {
  return {
    shopFixedCostList: [],
    totalPlatformFee: 0,
    totalRent: 0,
    totalLaborCostRegularEmployees: 0,
    totalOthers: 0
  };
}

const state = initialState();
const actions = {
  [FETCH_SHOP_FIXED_COST]: ({ commit, dispatch }, params) => {
    const { shop_id } = params;
    dispatch("API_PROCESSING", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/shops/${shop_id}/fixed-cost-monthly`, { params })
        .then(response => {
          commit(FETCH_SHOP_FIXED_COST_SUCCESS, response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [UPDATE_SHOP_FIXED_COST]: ({ commit, dispatch }, params) => {
    const { shop_id, id } = params;
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/shops/${shop_id}/fixed-cost-monthly/${id}`, params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CREATE_SHOP_FIXED_COST]: ({ commit, dispatch }, params) => {
    const { shop_id } = params;
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/shops/${shop_id}/fixed-cost-monthly`, params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
const mutations = {
  [FETCH_SHOP_FIXED_COST_SUCCESS]: (state, params) => {
    state.shopFixedCostList = params.data.fixed_cost_monthly;
    state.totalPlatformFee = params.data.total_platform_fee;
    state.totalRent = params.data.total_rent;
    state.totalLaborCostRegularEmployees =
      params.data.total_labor_cost_regular_employees;
    state.totalOthers = params.data.total_others;
  }
};
const getters = {
  getShopFixedCostList: state => state.shopFixedCostList,
  getTotalRent: state => state.totalRent,
  getTotalCostRegularEmployees: state => state.totalLaborCostRegularEmployees,
  getTotalOthers: state => state.totalOthers,
  getTotalPlatformFee: state => state.totalPlatformFee
};
export default {
  state,
  getters,
  actions,
  mutations
};
